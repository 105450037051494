import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

import { Vehicle, VehicleHelper } from '../model/vehicle';

@Injectable()
export class DataService {

  returnUrl: string;

  constructor(public http: HttpService) { console.log("Instantiate dataService"); }

  // Use a new service more suited to this and other behaviours later.
  public newLocation(path: any): any[] {
    const result = (this.returnUrl || path);
    this.returnUrl = undefined;
    return Array.isArray(result) ? result : [result];
  }

  public newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  public errorMessage(message: string, title: string, messageClass: string, type: string) {
    return {
      message,
      title,
      messageClass,
      type
    }
  }

  public loadall(path: string): Observable<any> {
    return this.http.get(`/data/${encodeURI(path.toLowerCase())}`).map((res: Response) => { return res.json(); });
  }

  public load(id: any, path: string): Observable<any> {
    return this.http.get(`/data/${encodeURI(path.toLowerCase())}/${id}`).map((res: Response) => { return res.json(); });
  }

  public save(record: any, path: string): Observable<any> {
    return this.http.post(`/data/${encodeURI(path.toLowerCase())}/`, record).map((res: Response) => { return res.json(); });
  }

  public vehicles() : Observable<any[]> {
    return this.http.get('/data/vehicles')
        .map((res: Response) => {
          let vehicleHelper = new VehicleHelper();
          let results = res.json();
          for (let vehicle of results) {
            vehicle.hasvrm = vehicleHelper.validvrm(vehicle.vrm);
          }
          return results;
        });
  }

  public documents(parentType: string, parentItemId: any) : Observable<any[]> {
    return this.http.get(`/data/documents/${parentType}/${parentItemId}`)
      .map((res: Response) => { return res.json(); });
  }

  public vehicleDocuments(vehicleInternalReference: string) : Observable<any[]> {
    return this.http.get(`/data/vehicles/${vehicleInternalReference}/documents`)
      .map((res: Response) => { return res.json(); });
  }

  private getMimetype(fileTypes: Array<any>, file: any, signature: string): string {
    if (file.type) {
      return file.type;
    }

    let fileExtension = '';
    let e = file.name.toLowerCase().split('.');
    if (e.length > 1) { fileExtension = e[e.length-1]; }

    const matchingFileType =
      fileTypes.find(f => f.lookupValue == fileExtension) ||
      fileTypes.find(f => f.value == signature);

    return (matchingFileType) ? matchingFileType.lookupType : undefined;
  }

  public openInBrowser(mimeType: string): boolean {
    const fileTypes = this.fileTypes();
    const matchingFileType = fileTypes.find(f => f.lookupType == mimeType);
    return (matchingFileType && matchingFileType.local === true);
  }

  public processFileUpload(path: string, documentDetails: any, file: any): Promise<any> {
    const fileTypes = this.fileTypes();
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.onloadend = (evt: any) => {
        const uint = new Uint8Array(filereader.result as ArrayBuffer)
        let bytes = []
        uint.forEach((byte) => { bytes.push(byte.toString(16)) })
        const hex = bytes.join('').toUpperCase();
        documentDetails.mimeType =  this.getMimetype(fileTypes, file, hex);
        this.http.uploadFile(`/data/document/${path}`, file, documentDetails).subscribe(
          data => {
            let result = data.json()[0];
            if (result) {
              result.mimeType = documentDetails.mimeType;
              result.filename = documentDetails.filename;
            }
            resolve(result);
          },
          error => reject(error)
        )
      }
      const blob = file.slice(0, 4);
      filereader.readAsArrayBuffer(blob);
    });
  }

  public uploadDocument(path: string, data: any, details: any) : Observable<any> {
    return this.http.uploadFile(`/data/document/${path}`, data, details)
      .map((res: Response) => { return res.json(); });
  }

  public downloadDocument(serverResource: string, filename: string, mimeType?: string): Observable<any> {
    return this.http.downloadFile(`/data/document/download`, `${encodeURI(serverResource)}`, filename, mimeType);
  }

  public findByVrm(vrm: string): Observable<any> {
    return this.http.get(`/data/vehicles/vrmlookup/${vrm}`, undefined, false)
      .map((res: Response) => { return res.json(); });
  }

  public vehicle(id: number): Observable<Vehicle> {
    return this.http.get('/data/vehicles/' + id)
      .map((res: Response) => { return res.json(); });
  }

  public saveVehicle(data: Vehicle): Observable<any> {
    return this.http.post('/data/vehicles/', data)
      .map((res: Response) => { return res.json(); });
  }

  public getVehicleOptions(idscode: string): Observable<any> {
    return this.http.get(`/data/vehicles/${encodeURI(idscode)}/options`, undefined, false)
      .map((res: Response) => { return res.json(); });
  }

  public lookup(path: string, param?: string, showLoader: boolean = false): Observable<any> {
    const paramData = (param) ? ('/' + encodeURI(param)) : '';
    return this.http.get(`/data/lookup/${path}${paramData}`, undefined, showLoader)
      .map((res: Response) => {
        let data = res.json();
        localStorage.setItem(path, JSON.stringify(data));
        return data;
      });
  }

  public fileTypes(): any {
    let lookup = this.getFromLocalStorage("staticdata");
    return (lookup) ? lookup.filter(function(i) { return i.type == "fileType"; }) : [];
  }

  public vehicleStatusList(): any {
    const statusMapping: any = [
      { display: 'Available (Stock)', value: "available" },
      { display: 'Available (Future)', value: "availablefuture" },
      { display: 'Available (Web)', value: "availableweb" },
      { display: 'Awaiting Assessment', value: "awaitingassessment" },
      { display: 'Awaiting Collection', value: "awaitingcollection" },
      { display: 'Awaiting Delivery', value: "awaitingdelivery" },
      { display: 'Awaiting Inspection', value: "awaitinginspection" },
      { display: 'Awaiting Repair', value: "awaitingrepair" },
      { display: 'Awaiting Supplier', value: "awaitingsupplier" },
      { display: 'Awaiting Swap', value: "awaitingswap" },
      { display: 'Disposal', value: "disposal" },
      { display: 'Due off rental', value: "dueoffrental" },
      { display: 'Inspected', value: "inspected" },
      { display: 'Off fleet', value: "offfleet" },
      { display: 'On hire', value: "onhire" },
      { display: 'On route', value: "onroute" },
      { display: 'Reserved', value: "reserved" },
      { display: 'Reserved (Stock)', value: "awaitingdocs" },
      { display: 'Reserved (Future)', value: "reservedfuture" },
      { display: 'Tagged (Future)', value: "taggedfuture" },
      { display: 'Tagged (Stock)', value: "reservedstock" }
    ];
    return statusMapping;
  }

  public getStaticData(showLoader: boolean = false): Observable<any> {
    return this.lookup("staticdata");
  }

  public getManufacturers(): Observable<any> {
    return this.lookup("manufacturers");
  }

  public getModels(manufacturer): Observable<any> {
    return this.http.get('/data/lookup/models/' + encodeURI(manufacturer), undefined, false)
      .map((res: Response) => { return res.json(); });
  }

  public getVariants(manufacturer, model): Observable<any> {
    return this.http.get(`/data/lookup/variants/${encodeURI(manufacturer)}/${encodeURI(model)}`, undefined, false)
      .map((res: Response) => { return res.json(); });
  }

  public getFromLocalStorage(path): any {
    const data = localStorage.getItem(path);
    return (data) ? JSON.parse(localStorage.getItem(path)) : undefined;
  }

  public dateStr(): string {
    const d = new Date();
    let min: any = d.getMinutes();
    let sec: any = d.getSeconds();
    if (min < 10) { min = `0${min}`; }
    if (sec < 10) { sec = `0${min}`; }
    return `${d.getDate()}/${d.getMonth()+1} ${d.getHours()}:${min}:${sec}.${d.getMilliseconds()}`;
  }

  public traceMsg(msg: string, category?: string): void {
    console.log(`${this.dateStr()} ${category || 'trace'} :: ${msg}`);
  }

}
