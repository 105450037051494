import { Injectable, Output, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map'; 
import 'rxjs/add/operator/catch';

import { HttpService } from './http.service';
import * as JWT from 'jwt-decode';

@Injectable()
export class UserService {

  redirectUrl: string;
  user: any;
  claims: any = {};
  isAuthenticated: boolean = false;

  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpService, private router: Router) { 
    this.getIsAuthenticated();
  }

  private getIsAuthenticated() {
    let result = false;
    this.user = { displayname: "(not logged in)" }
    let _user = localStorage.getItem('currentUser');
    if (_user) {
        this.user = JSON.parse(_user);        
        this.claims = JWT(this.user.token);
        let now = new Date();
        let tokenExpiry = this.user.expiryTime;
        if (typeof tokenExpiry === "string") {
            tokenExpiry = new Date(tokenExpiry);
        }
        if (this.user.token && tokenExpiry !== undefined && tokenExpiry > now) {
            // We appear to be legitimately logged in. Nothing to see here!
            result = true;
        }
    }
    this.isAuthenticated = result;
    //return result;
  }

  public clearToken() {
    let _user = localStorage.getItem('currentUser');
    if (_user) {
        localStorage.removeItem('currentUser');            
        return true;
    }
    return false;
  }

  private userAuthChanged(status: boolean) {
    this.authChanged.emit(status); //Raise changed event
  }

  public getClaims(): any {
    if (this.user && this.user.token) {
      let t = JWT(this.user.token);
      return t;
    }
    return {};
  }

  login(userLogin: any) : Observable<boolean> {
    /*
    this.http.get('/data/vehicles/type/2063').subscribe(
      res => console.log(res.json()),
      msg => console.error(`error:: ${msg.status} ${msg.statusTest}`)
    );
    */
    return this.http.post('/api/auth', userLogin)
               .map((response: Response) => {
                    let resultPackage = response.json();
                    if (resultPackage && resultPackage.token) {
                        this.isAuthenticated = true;
                        this.user = resultPackage;
                        this.claims = JWT(this.user.token);
                        localStorage.setItem('currentUser', JSON.stringify(resultPackage));
                        this.userAuthChanged(true);
                        return true;
                    }
                    else {
                        return false;
                    }
               });
  }

  register(account: any): Observable<boolean> {
      return this.http.post('/data/account/register', account)
          .map((response: Response) => { return true; });
  }

  logout() : boolean {
    this.isAuthenticated = false;
    this.user = { displayname: "(not logged in)" }
    this.claims = { }
    localStorage.removeItem('currentUser');
    this.userAuthChanged(false);
    return true;
  }
}
