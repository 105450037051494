import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { SimpleGridComponent } from '../base/base.component';
import { DialogJournalComponent } from '../shared/dialog/dialog.journal.component';

@Component({
  selector: 'item-journal',
  templateUrl: './itemjournal.component.html',
  styleUrls: ['./itemjournal.component.scss']
})
export class ItemJournalComponent extends SimpleGridComponent implements AfterViewInit {

    @Input() id: any = undefined;
    @Input() title: string = "Journal";
    @Input() parentType: string = "all";
    @Input() dateFilters: boolean = true;
    @Input() autoOpen: boolean = true;
    @Input() pageSizes: any = [ 4 ];

    collectionName = "journal";
    dataColumns = [ "icon", "text", "allocatedUser", "action" ];

    ngAfterViewInit() {
      this.collectionName = `journal/${this.parentType}/${this.id}`;
      if (this.autoOpen) {
        this.updateCollection();
      }
    }

    onLoaded() {

    }

    createDelivery(row) {
      // Create a new delivery movement for this rental
    }

    public editJournal(row: any): any {
      let dialog = this.dialog.open(DialogJournalComponent, {
        data: row
      });
      dialog.afterClosed().subscribe(result => {
        // result will be the amended row
      });

    }

}
