import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { JournalService } from '../services/journal.service';
import { ContactSelectComponent } from '../contactselect/contactselect.component';

@Component({
  selector: 'journalentry',
  templateUrl: './journalentry.component.html',
  styleUrls: ['./journalentry.component.scss']
})
export class JournalEntry extends BaseComponent implements OnInit {

  @Input('customerId') customerId: number;
  @Input('customerInternalReference') customerInternalReference: string;

  @ViewChild("contactSelect") contactSelect: ContactSelectComponent;

  /*
  CREATE PROCEDURE [dbo].[spAddJournal2]
    @username [nvarchar](100) = null
   ,@forMinimumRole [nvarchar](50) = null
   ,@text [nvarchar](500) = null
   ,@vehicleId [int] = null
   ,@orderId [int] = null
   ,@contactId [int] = null
   ,@customerId int = null
   ,@dateActionRequired [datetime] = NULL
   ,@hoursBeforeActionRequired [int] = NULL
   ,@priority int = 4
   ,@dateActive datetime = null

  */

  public title: string = "New journal entry";
  private service = new JournalService(this.dataService);
  public userList: any = [];

  ngOnInit() { 
    this.load();
    this.record.priority = 4;
    this.loadUserList();
  }


  loadUserList() {
    this.dataService.loadall(`users`)
      .subscribe(
        (response: any[]) => { this.userList = response; },
        (error: any) => console.log("Failed to load list of users to assign this entry to")
    );
  }


  cancelForm() {
    this.router.navigate(this.dataService.newLocation("home"));
  }

  onLoaded() { }

  onSubmit(form) {
    if (!form.valid) {
      this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");  
      return false;
    }
    this.service.save(this.record)
      .then(data => {
        this.growler.growl(`The journal entry has been saved.`, "Save complete", "growl-info-header", "info");  
        this.router.navigate(this.dataService.newLocation("home"));
      })
      .catch(err => this.handleError(err, "Failed to save journal entry"))
  }

  customerChanged(customer: any) {
    this.record.customerAccountId =customer.id;
    this.record.contactId = undefined;
    this.contactSelect.collectionName = (customer.id) ? `contacts/customer/${customer.id}` : undefined;
    this.contactSelect.updateCollection();
  }

  contactChanged(contact: any) {
    this.record.contactId = contact.id;
  }


}
