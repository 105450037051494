import { Component, OnInit, ViewChild } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent extends GridSelectComponent implements OnInit {

  collectionName = "orders";
  dataColumns = [ "vrm", "filter", "status" ];

  private orderFilter = new FormControl();
  private statusFilter = new FormControl();

  statusMapping = [
    { "display": "Pending", "value": "pending"},
    { "display": "Active", "value": "active"}
  ];

  defaultFilter: any = undefined;
  activeFilter: any = { statusFilter: [] };

  ngOnInit() {
    this.orderFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.orderFilter = value;
        this.applyOrderFilter(this.activeFilter.orderFilter, this.activeFilter.statusFilter);
      })
    this.statusFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.statusFilter = value;// ?  value.replace(/\s+/g, '').split(',') : [];
        this.applyOrderFilter(this.activeFilter.orderFilter, this.activeFilter.statusFilter);
      })
  }

  onLoaded() {

  }
  

  select(row) {
    this.dataService.returnUrl = "orders";
    this.router.navigate(["order", row.id]);
  }

  applyOrderFilter(orderFilter: string, statusFilter: string) {

    function hasMatchingProperty(data: any, filter: string, propertyName: string, propertyValue: string) {
      const activeFilterObject: any = JSON.parse(filter);
      const filterValue = activeFilterObject[propertyValue];
      if (!filterValue || filterValue.length == 0) {
          return true;
      }
      else {
        let dataValue = data[propertyName];
        if (propertyName == "status") {
          if (!dataValue) {
            return false;
          }
          dataValue = dataValue.replace(/\s+/g, '');
          return filterValue.indexOf(dataValue.toLowerCase()) > -1;
        }
        else
          return (dataValue && dataValue.toLowerCase().indexOf(filterValue) != -1);
      }
    }

    if (!this.dataSource) {
      return;
    }

    if (orderFilter || statusFilter) {
      if (!this.defaultFilter) {
        // Ensure we have the default filter to roll back to
        this.defaultFilter = this.dataSource.filterPredicate;
      }

      let filterObject = JSON.stringify({ 
        value: orderFilter ? orderFilter.toLowerCase() : undefined, 
        status: statusFilter ? statusFilter : [] 
      });

      this.dataSource.filterPredicate = (data: any, filter: string) => hasMatchingProperty(data, filter, "filter", "value") && hasMatchingProperty(data, filter, "status", "status");
      this.dataSource.filter = filterObject;
    }
    else {
      // Roll back to the original
      this.dataSource.filterPredicate = this.defaultFilter;
      this.dataSource.filter = undefined;
    }
  }  

}
