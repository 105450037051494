import { Component, OnInit } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'customerselect',
  templateUrl: './customerselect.component.html',
  styleUrls: ['./customerselect.component.scss']
})
export class CustomerSelectComponent extends GridSelectComponent implements OnInit {

  collectionName = "customers";
  dataColumns = [ "icon", "customerName" ];

  ngOnInit() {
    this.initialise(this.id, "customer");
  }

  onLoaded() {

  }
  
  select(row) {
    this.updateId(row.id);
  }
}
