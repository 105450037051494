import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class InvoiceService {

  constructor(private dataService: DataService) { }

  public validate(record: any): any {
    if (!record.customerInternalReference) {
        return this.dataService.errorMessage(`Please select the customer for this invoice.`, "Further information needed", "growl-info-header", "info");
    }

    return undefined;
  }

  public save(record: any): any {
    return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        this.dataService.save(this.submittableRecord(record), "invoice").subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public init(record: any) {
    const curr = {style: "currency", currency: "GBP", minimumFractionDigits: 2};
    if (record.lines===undefined) {
      record.lines = [];
    }
    record.total = 0;
    for (let l of record.lines) {
      record.total += l.cost;
      record.display = `${record.description} @ ${record.cost.toLocaleString("en-GB", curr)}`;
      if (!record.id) {
        record.id = this.dataService.newGuid()
      }
    }
    record.displayTotal = record.total.toLocaleString("en-GB", curr);
  }

  public addLine(record: any, description: string, summary: string, cost: number) {
    const curr = {style: "currency", currency: "GBP", minimumFractionDigits: 2};

    record.lines.push({ 
      "description": description, 
      "summary": summary, 
      "cost": cost, 
      "id": this.dataService.newGuid(),
      "display": `${description} @ ${cost.toLocaleString("en-GB", curr)}`
    });
    record.total += cost;
    record.displayTotal = record.total.toLocaleString("en-GB", curr);
  }

  public removeLine(record: any, line: any) {
    const curr = {style: "currency", currency: "GBP", minimumFractionDigits: 2};
    if (!line) {
      return;
    }
    const index = record.lines.findIndex(x => x.id == line.id);
    if (index > -1) {
      record.total -= line.cost;
      record.lines.splice(index, 1);
      record.displayTotal = record.total.toLocaleString("en-GB", curr);
    }
  }


  // Make sure we have a clean record for saving
  private submittableRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    delete saveRecord.valid;
    for (let l of saveRecord.lines) {
      delete l.display;
      delete l.id;
    }
    saveRecord.invoiceType = 'adhoc-invoice';
    /*
   ,@vehicleId int = null
   ,@chargingStartDate [date] = null
   ,@chargingEndDate [date] = null
   ,@vatRate [int]
   ,@cost [money]
   ,@description [nvarchar](500) = null
   ,@summary [nvarchar](500) = null

    */
    return saveRecord;
  }

}
