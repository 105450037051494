import { NgModule, Optional, SkipSelf, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { GrowlerModule } from './growler/growler.module';


@NgModule({
  imports: [ 
    CommonModule,     
    GrowlerModule  
  ],
  exports: [ 
    GrowlerModule 
  ],
  providers: [  ] // these should be singleton
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {    //Ensure that CoreModule is only loaded into AppModule

  //Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }  

}



