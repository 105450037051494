import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { FleetComponent } from './fleet/fleet.component';
import { CustomersComponent } from './customers/customers.component';
import { QuotationsComponent } from './quotations/quotations.component';
import { CustomerComponent } from './customer/customer.component';
import { QuotationComponent } from './quotation/quotation.component';
import { OrderComponent } from './order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { AuthGuard } from './core/auth-guard';
import { UserComponent } from './user/user.component';
import { UsersComponent } from './users/users.component';
import { DocumentTypesComponent } from './documenttypes/documenttypes.component';
import { MovementsConponent } from './movements/movements.component';
import { NewMovementConponent } from './newmovement/newmovement.component';
import { JournalEntry } from './journalentry/journalentry.component';
import { ReportComponent } from './report/report.component';
import { ReportsComponent } from './reports/reports.component';
import { InvoiceComponent } from './invoice/invoice.component';

//{ path: 'vehicles', component: VehiclesComponent, canActivate: [ AuthGuard ] },
const routes: Routes = [
  { path: '', pathMatch:'full', redirectTo: '/home' },
  { path: 'home', component: HomeComponent, canActivate: [ AuthGuard ] },
  { path: 'newjournal', component: JournalEntry, canActivate: [ AuthGuard ] },
  { path: 'login/:reason', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'vehicles', component: FleetComponent, canActivate: [ AuthGuard ] },
  { path: 'vehicle/:id', component: VehicleComponent, canActivate: [ AuthGuard ] },
  { path: 'customers', component: CustomersComponent, canActivate: [ AuthGuard ] },
  { path: 'quotations', component: QuotationsComponent, canActivate: [ AuthGuard ] },
  { path: 'customer/:id', component: CustomerComponent, canActivate: [ AuthGuard ] },
  { path: 'quotation/:id', component: QuotationComponent, canActivate: [ AuthGuard ] },
  { path: 'quotation/:id/:vehicleId', component: QuotationComponent, canActivate: [ AuthGuard ] },
  { path: 'orders', component: OrdersComponent, canActivate: [ AuthGuard ] },
  { path: 'order/:id', component: OrderComponent, canActivate: [ AuthGuard ] },
  { path: 'order/:id/:quotationId', component: OrderComponent, canActivate: [ AuthGuard ] },
  { path: 'order/:id/:quotationId/:vehicleId', component: OrderComponent, canActivate: [ AuthGuard ] },
  { path: 'users', component: UsersComponent, canActivate: [ AuthGuard ] },
  { path: 'user/:id', component: UserComponent, canActivate: [ AuthGuard ] },
  { path: 'documenttypes', component: DocumentTypesComponent, canActivate: [ AuthGuard ] },
  { path: 'report/:id', component: ReportComponent, canActivate: [ AuthGuard ] },
  { path: 'report', component: ReportComponent, canActivate: [ AuthGuard ] },
  { path: 'reports', component: ReportsComponent, canActivate: [ AuthGuard ] },
  { path: 'invoice', component: InvoiceComponent, canActivate: [ AuthGuard ] },
  { path: 'movements', component: MovementsConponent, canActivate: [ AuthGuard ] },
  { path: 'newmovement/:orderId/:journalId', component: NewMovementConponent, canActivate: [ AuthGuard ] },
];
//{ path: 'customer/:id', component: CustomerComponent, canActivate: [ AuthGuard ] }

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ AuthGuard ]
})
export class AppRoutingModule { }
