import { ElementRef } from '@angular/core';
import { DataService } from './data.service';

export class MappingService {

  private platform: any;
  private geocoder: any;
  private routing: any;
  private home: any;

  constructor(private instance: any, private mapElement: ElementRef, private initParams: any, private dataService: DataService) {
    if (!this.initParams) {
      this.initParams = { zoom: 10, center: { lat: 52.924630, lng: -0.053030 } }
    }

    this.home = `geo!${this.initParams.center.lat},${this.initParams.center.lng}`;

    this.platform = new this.instance.service.Platform({
      "app_id": "pWBKP5YRXE989dgOFIzn",
      "app_code": "kHp3cu4oOSjp08Aoz1ms3A",
      "useHTTPS": true
    });

    const defaultLayers = this.platform.createDefaultLayers();
    const map = new this.instance.Map(this.mapElement.nativeElement, defaultLayers.normal.map, this.initParams);
  }

  // Inspects the location object for lat lon and returns a geo waypoint
  public getGeoFromLocation(location: any): string {
    //this.dataService.save()
    if (!location) {
      return undefined;
    }
    if (location.NavigationPosition && location.NavigationPosition.length > 0 && location.NavigationPosition[0].Latitude) {
      return `geo!${location.NavigationPosition[0].Latitude},${location.NavigationPosition[0].Longitude}`;
    } else if (location.DisplayPosition && location.DisplayPosition.Latitude) {
      return `geo!${location.DisplayPosition.Latitude},${location.DisplayPosition.Longitude}`;
    } else {
      return undefined;
    }
  }

  public priceRouteKM(route: any, minimum: any): any {
    if (!minimum) {
      minimum = 25;
    }
    let result = { mileage: -1, cost: minimum };
    if (route && route.distance) {
      result.mileage = (route.distance / 8000) * 5;
      const routeCost = result.mileage * 1.2;
      result.cost = routeCost.toFixed(2);
      if (result.cost < minimum) {
        result.cost = minimum;
      }
    }
    return result;
  }

  public directions(destination: string, source: string) {
    if (!this.routing) {
      this.routing = this.platform.getRoutingService();
    }
    const routingParameters = {
      'mode': 'fastest;car',
      'waypoint0': destination,
      'waypoint1': source || this.home,
      'representation': 'overview'
    };

    return new Promise((resolve, reject) => {
      this.routing.calculateRoute(routingParameters, (result: any) => {
        if (result && result.response && result.response.route && result.response.route.length > 0) {
          return resolve(result.response.route[0].summary);
        } else {
          return reject({ "message": "Unable to map location" });
        }
      }, (err: any) => {
        return reject({ "message": "Unable to map location" });
      });
    });
  }

  public geocode(location: any) {
    if (!this.geocoder) {
      this.geocoder = this.platform.getGeocodingService();
    }

    return new Promise((resolve, reject) => {
      this.geocoder.geocode(location, (result: any) => {
        let resultView: any;
        if (result.Response && result.Response.View && result.Response.View.length > 0)  {
          resultView = result.Response.View[0].Result[0].Location;
        }
        if (resultView) {
          return resolve(resultView);
        } else {
          return reject({ "message": "Unable to map location" });
        }
      }, (err: any) => {
        return reject({ "message": "Unable to map location" });
      });
    });
  }

}
