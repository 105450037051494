import { Component, OnInit, ViewChild, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { EmailComponent } from '../email/email.component';
import { QuotationService } from '../services/quotation.service';
import {saveAs as importedSaveAs} from "file-saver";
import { DialogMapComponent } from '../shared/dialog/dialog.map.component';

@Component({
  selector: 'quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class QuotationComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild("email") email: EmailComponent;

  // Should be using DI for this, but it's not implementation specific so isn't really relevant
  private service = new QuotationService(this.dataService);

  public title: string = "New quotation";
  salesReps: any = [];

    ngOnInit() {
      this.loadUserList();
      this.route.params.subscribe((params: Params) => {
        const id = +params['id'];
        // If there's no order id, we need a quotation id to load on which we'll base the order
        if (!id) {
          const vehicleId = +params['vehicleId'];
          this.initialise(id, 'quotation');
          if (vehicleId) {
            this.record.vehicleId = vehicleId;
            this.service.tagVehicle(vehicleId)
              .then(data => { })
              .catch(err => this.handleError(err, 'A problem occured when trying to tag the vehicle'));
          }
        }
        else {
          this.initialise(id, 'quotation');
        }
      });
    }

    ngAfterViewInit() {
      if (this.record.id) {
        return;
      }

      this.generateReference();
      this.record.chargeDeliveryFee = true;
      this.record.chargeCollectionFee = true;
    }

    loadUserList() {
      this.dataService.loadall(`users`)
        .subscribe(
          (response: any[]) => { this.salesReps = response; },
          (error: any) => console.log("Failed to load list of sales reps")
      );
    }

    onCalcDelivery() {
      let dialog = this.dialog.open(DialogMapComponent, {
        data: { destination: this.record.deliveryPostcode }
      });
      dialog.afterClosed().subscribe(result => {
        // result will be the amended row
        if (result && result.cost) {
          this.record.deliveryFee = result.cost;
          this.record.collectionFee = result.cost;
        }
      });
    }

    onRecordChanged(quotation: any) {

    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");
        return false;
      }
      this.service.save(this.record)
        .then(data => {
          if (!this.record.id) {
            this.record.id = data.parentId;
          }
          this.growler.growl(`The quotation has been saved.`, "Save complete", "growl-info-header", "info");
          this.router.navigate(this.dataService.newLocation("quotations"));
        })
        .catch(err => this.handleError(err, "Failed to save quotation"))
    }

    public generateReference() {
      this.record.quotationReference = this.service.generateReference();
    }
    public sendEmail() {
      this.service.save(this.record)
        .then(data => {
          if (!this.record.id) {
            this.record.id = data.parentId;
          }
          this.email.addAttachment(data);
          this.email.send(`Please find attached quotation.`);
        })
        .catch(err => this.handleError(err, "Failed to save quotation"))
    }
    public showPdf() {
      let tab =  window.open();
      this.service.save(this.record)
        .then(doc => {
          if (!this.record.id) {
            this.record.id = doc.parentId;
          }
          this.dataService.downloadDocument(doc.documentId, doc.filename, doc.mimeType)
          .subscribe(data => {
            const openInBrowser = this.dataService.openInBrowser(doc.mimeType);
            if (openInBrowser) {
              tab.location.href = URL.createObjectURL(data);
            }
            else {
              tab.close();
              importedSaveAs(data, doc.filename);
            }
          });
        })
        .catch(err => {
          tab.close();
          this.handleError(err, "Failed to save quotation");
        })
    }

    createOrder() {
      let msg = this.service.canCreateOrder(this.record);
      if (msg) {
        this.growler.growl(msg, "Cannot create order", "growl-error-header", "info");
        return;
      }

      this.dataService.returnUrl = "orders";
      this.router.navigate(["order", 0, this.record.id]);
    }

    onLoaded() {
      this.record.valid = this.record.monthlyPayment && this.record.initialPayment && this.record.initialTerm && this.record.termMonths && this.record.contractMileage;
      this.title = "Editing quotation";
    }

    customerChanged(customer: any) {
      this.record.customerAccountId =customer.id;
      if (customer && !customer.activeRentals) {
        this.record.chargeAdminFee = true;
        this.record.adminFee = 250;
        this.growler.growl(`The customer doesn't appear to have any active rentals, we've added an admin fee.`, "Admin fee payable", "growl-info-header", "info");
      }
      this.record.deliveryPostcode = customer.postcode;
      // Need to clear the contacts list
      this.email.clearContacts();
    }

    vehicleChanged(vehicle: any) {
      this.record.vehicleId = vehicle.id;
      this.record.initialTerm = vehicle.initialTerm;
      this.record.monthlyPayment = vehicle.monthlyPayment;
      this.record.termMonths = vehicle.termMonths;
      this.record.contractMileage = vehicle.contractMileage;
      this.record.initialPayment = this.record.initialTerm * this.record.monthlyPayment;
      this.record.valid = this.record.monthlyPayment && this.record.initialPayment && this.record.initialTerm && this.record.termMonths && this.record.contractMileage;
    }

    reloadRequest() {
      this.load();
    }

}
