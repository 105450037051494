import { Component, OnInit } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends GridSelectComponent implements OnInit {

  collectionName = "reports";
  dataColumns = [ "title", "description" ];
  userRole: string;

  ngOnInit() {
    //this.userRole = this.userService.claims.role;
  }

  onLoaded() {

  }

  selectRow(row) {
    this.dataService.returnUrl = "reports";
    this.router.navigate(["report", row.id]);
  }

}
