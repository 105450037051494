import { Component, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { MovementService } from '../services/movement.service';

@Component({
  selector: 'movement',
  templateUrl: './movement.component.html',
  styleUrls: ['./movement.component.scss']
})
export class MovementConponent extends BaseComponent implements OnInit {

  private service = new MovementService(this.dataService);
  drivers: any = [];
  
  public title: string = "New movement job";

    ngOnInit() {
      this.loadDriverList();
      this.route.params.subscribe((params: Params) => {
        let id = +params['id'];
      });
    }

    loadDriverList() {
      this.dataService.loadall(`users/role/driver`)
        .subscribe(
          (response: any[]) => { this.drivers = response; },
          (error: any) => console.log("Failed to load list of drivers")
      );
    }

    onRecordChanged(quotation: any) {

    }
      
    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");  
        return false;
      }
      this.service.save(this.record)
        .then(data => {
          this.growler.growl(`The movement has been saved.`, "Save complete", "growl-info-header", "info");  
          this.router.navigate(this.dataService.newLocation("movements"));
        })
        .catch(err => this.handleError(err, "Failed to save movement"))
    }
    onLoaded() {
      this.entity = 'movement';
      this.title = "Editing vehicle movement job";
    }

}
