import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fleet-options',
  templateUrl: './fleet-options.component.html',
  styleUrls: ['./fleet-options.component.scss']
})
export class FleetOptionsComponent implements OnInit {

  modalClass = 'hide-modal';
  warnClass = '';
  record: any = {};

  constructor(protected router: Router) { }

  @Output() fullEditRequested: EventEmitter<any> = new EventEmitter<any>();

  
  ngOnInit() {
  }

  public edit(data: any) {
    Object.assign(this.record, data);
    if (this.record.imageFilename) {
      this.record.imageFilename = `https://legacyapi.seabeckconsulting.co.uk/img/vehicles/thumbs/${this.record.imageFilename}`;
    } else {
      this.record.imageFilename = `https://legacyapi.seabeckconsulting.co.uk/img/no-image.jpg`;
    }
    this.warnClass = '';
    this.modalClass = "show-modal";
  }

  public load(data: any) {
    this.record = data;
    this.modalClass = "show-modal";
  }
  
  public hide() {
    this.modalClass = "hide-modal";
  }

  public tagVehicle() {
    this.hide();
    this.router.navigate(["quotation", 0, this.record.id]);
  }

  public fullEdit() {
    this.hide();
    this.fullEditRequested.emit(this.record.id);
  }

  public donothing(e) {
    e.stopPropagation();
  }

  private infoNeeded() {
    let result = "";

    return result ? `${result} required!` : '';
  }

}
