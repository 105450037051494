import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';
import { GrowlerService } from '../core/growler/growler.service';
import { DialogVehicleComponent } from '../shared/dialog/dialog.vehicle.component';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  displayedColumns = [ "vrm", "filter", "location", "status"];
  dataSource: MatTableDataSource<any>;

  public vehicleFilter = new FormControl();
  public statusFilter = new FormControl();

  defaultFilter: any = undefined;
  activeFilter: any = { statusFilter: [] };
  statusMapping: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private growler: GrowlerService,
    private dataService: DataService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.statusMapping = this.dataService.vehicleStatusList();
    this.getData();

    this.vehicleFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.vehicleFilter = value;
        this.applyVehicleFilter(this.activeFilter.vehicleFilter, this.activeFilter.statusFilter);
      })
    this.statusFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.statusFilter = value;// ?  value.replace(/\s+/g, '').split(',') : [];
        this.applyVehicleFilter(this.activeFilter.vehicleFilter, this.activeFilter.statusFilter);
      })
  }

  getData() {
    this.dataService.vehicles()
        .subscribe((response: any[]) => {
            if (!this.dataSource) {
              this.dataSource = new MatTableDataSource(response);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          },
          (error: any) => this.handleApiError(error, "Failed to load vehicles")
        );
  }
  handleApiError(err, title) {
    if (err.loginRedirectRequired && err.loginRedirectRequired === true) {
      this.userService.logout();
      this.growler.growl("Your login session has expired, you will need to log again in to continue.", "Warning", "growl-info-header", "info");
      this.router.navigate(["login"]);
    }
    else {
      this.growler.growl(err.detail || "An unknown error occured", title, "growl-error-header", "error");
    }
  }

  selectRow(row: any) {
    //console.log(`debug:: Root snapshot = ${this.router.routerState.snapshot.url}`);
    this.dataService.returnUrl = "vehicles";
    this.router.navigate(["vehicle", row.id]);
  }

  /*
  selectRow(row: any) {
    let dialog = this.dialog.open(DialogVehicleComponent, {
      data: row
    });
    dialog.afterClosed().subscribe(result => {
    });
  }
  */

  selectOrder(e: any,row:any) {
    e.stopPropagation();
    this.dataService.returnUrl = "vehicles";
    this.router.navigate(["order", row.orderId]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase(); // Datasource defaults to lowercase matches
  }

  applyVehicleFilter(vehicleFilter: string, statusFilter: string) {

    function hasMatchingProperty(data: any, filter: string, propertyName: string, propertyValue: string) {
      const activeFilterObject: any = JSON.parse(filter);
      const filterValue = activeFilterObject[propertyValue];
      if (!filterValue || filterValue.length == 0) {
          return true;
      }
      else {
        let dataValue = data[propertyName];
        if (propertyName == "status") {
          if (!dataValue) {
            return false;
          }
          dataValue = dataValue.replace(/\s+/g, '');
          return filterValue.indexOf(dataValue.toLowerCase()) > -1;
        }
        else
          return (dataValue && dataValue.toLowerCase().indexOf(filterValue) != -1);
      }
    }

    if (!this.dataSource) {
      return;
    }

    if (vehicleFilter || statusFilter) {
      if (!this.defaultFilter) {
        // Ensure we have the default filter to roll back to
        this.defaultFilter = this.dataSource.filterPredicate;
      }

      let filterObject = JSON.stringify({
        value: vehicleFilter ? vehicleFilter.toLowerCase() : undefined,
        status: statusFilter ? statusFilter : []
      });

      this.dataSource.filterPredicate = (data: any, filter: string) => hasMatchingProperty(data, filter, "filter", "value") && hasMatchingProperty(data, filter, "status", "status");
      this.dataSource.filter = filterObject;
    }
    else {
      // Roll back to the original
      this.dataSource.filterPredicate = this.defaultFilter;
      this.dataSource.filter = undefined;
    }
  }

}
