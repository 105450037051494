import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends GridSelectComponent implements OnInit {

  collectionName = "users";
  dataColumns = [ "displayName", "emailAddress", "role" ];
  userRole: string;

  ngOnInit() {
    this.userRole = this.userService.claims.role;
  }

  onLoaded() {

  }
  
  selectRow(row) {
    let acceptableEdits = [
      { me: "sysadmin", excludes: [] },
      { me: "admin", excludes: ["sysadmin"] },
      { me: "supervisor", excludes: ["admin", "sysadmin"] },
      { me: "standard", excludes: ["supervisor", "admin", "sysadmin"] }
    ];

    let editProfile = acceptableEdits.find(e => e.me == this.userRole);

    if (!editProfile || editProfile.excludes.indexOf(row.role) > -1) {
      this.growler.growl("You don't have permission to edit this user account.", "Warning", "growl-info-header", "info");  
      return false;
    }

    this.dataService.returnUrl = "users";
    this.router.navigate(["user", row.id]);
  }

}
