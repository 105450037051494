import { Component, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { EmailComponent } from '../email/email.component';
import { OrderService } from '../services/order.service';
import {saveAs as importedSaveAs} from "file-saver";
import { DialogDateComponent } from '../shared/dialog/dialog.date.component';
import helper from '../shared/helper';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BaseComponent implements OnInit {

  @ViewChild("email") email: EmailComponent;

  // Should be using DI for this, but it's not implementation specific so isn't really relevant
  private service = new OrderService(this.dataService);
  salesReps: any = [];

  public title: string = 'New order';

    ngOnInit() {
      this.loadUserList();
      this.route.params.subscribe((params: Params) => {
        const id = +params['id'];
        // If there's no order id, we need a quotation id to load on which we'll base the order
        if (!id) {
          const quotationId = +params['quotationId'];
          const vehicleId = +params['vehicleId'];

          this.initialise(quotationId, 'order/prepare');
          if (vehicleId) {
            this.record.vehicleId = vehicleId;
            this.record.orderReference = this.service.generateReference();
            this.service.tagVehicle(vehicleId)
              .then(data => { })
              .catch(err => this.handleError(err, 'A problem occured when trying to tag the vehicle'));
          }
        } else {
          this.initialise(id, 'order');
        }
      });
    }

    loadUserList() {
      this.dataService.loadall(`users`)
        .subscribe(
          (response: any[]) => { this.salesReps = response; },
          (error: any) => console.log('Failed to load list of sales reps')
      );
    }

    onRecordChanged(quotation: any) {

    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");
        return false;
      }
      this.service.save(this.record)
        .then(data => {
          if (!this.record.id) {
            this.record.id = data.parentId;
          }
          this.growler.growl(`The order has been saved.`, "Save complete", "growl-info-header", "info");
          this.router.navigate(this.dataService.newLocation("orders"));
        })
        .catch(err => this.handleError(err, "Failed to save order"))
    }
    public sendEmail() {
      this.service.save(this.record)
        .then(data => {
          if (!this.record.id) {
            this.record.id = data.parentId;
          }
          this.email.addAttachment(data, false);
          this.email.send(`Please find attached order details.`);
        })
        .catch(err => this.handleError(err, "Failed to save order"))
    }
    public createDocuments() {
      this.service.save(this.record)
        .then(data => {
          if (!this.record.id) {
            this.record.id = data.parentId;
          }
          this.email.addAttachment(data, false);
        })
        .catch(err => this.handleError(err, "Failed to save order"))
    }
    public showPdf() {
      let tab =  window.open();
      this.service.save(this.record)
        .then(doc => {
          if (!this.record.id) {
            this.record.id = doc.parentId;
          }
          this.dataService.downloadDocument(doc.documentId, doc.filename, doc.mimeType)
          .subscribe(data => {
            const openInBrowser = this.dataService.openInBrowser(doc.mimeType);
            if (openInBrowser) {
              tab.location.href = URL.createObjectURL(data);
            }
            else {
              tab.close();
              importedSaveAs(data, doc.filename);
            }
          });
        })
        .catch(err => {
          tab.close();
          this.handleError(err, "Failed to save order");
        })
    }

    onLoaded() {
      this.entity = 'order';
      this.title = "Editing vehicle order";
    }

    driverChanged(driver: any) {
      this.record.driverContactId = driver.id;
    }

    vehicleChanged(vehicle: any) {
      this.record.vehicleId = vehicle.id;
      this.record.initialTerm = vehicle.initialTerm;
      this.record.monthlyPayment = vehicle.monthlyPayment;
      this.record.termMonths = vehicle.termMonths;
      this.record.contractMileage = vehicle.contractMileage;
      this.record.initialPayment = this.record.initialTerm * this.record.monthlyPayment;
      this.record.valid = this.record.monthlyPayment && this.record.initialPayment && this.record.initialTerm && this.record.termMonths && this.record.contractMileage;
    }

    rentalStatus() {
      if (this.record.chargingEndDate) {
        return "Status CLOSED - rental completed";
      }

      if (this.record.chargingStartDate) {
        return `Status ACTIVE from ${helper.displayDate(this.record.chargingStartDate)}`;
      }

      if (this.record.requiredDate) {
        return `Status PENDING - Required ${helper.displayDate(this.record.requiredDate)}`;
      }

      return "(please specify required date)";
    }

    onHire() {
      let dialog = this.dialog.open(DialogDateComponent, {
        data: { title: "On Hire", question: 'Are you sure you want to put this rental on hire? This should only be done once the customer has taken delivery of the vehicle.' },
      });
      dialog.afterClosed().subscribe(dt => {
        if (!dt) { return }
        this.service.onhire(this.record, dt)
          .then(data => {
            this.growler.growl(`This order is now on hire, any required invoices will be raised shortly.`, "On Hire", "growl-info-header", "info");
            this.router.navigate(this.dataService.newLocation("orders"));
          })
          .catch(err => this.handleError(err, "Failed to set this rental on hire"))
      });
    }

    offHire() {
      let dialog = this.dialog.open(DialogDateComponent, {
        data: { title: "Off Hire", question: 'Are you sure you want to take this rental off hire?' },
      });
      dialog.afterClosed().subscribe(dt => {
        if (!dt) { return }
        this.service.offhire(this.record, dt)
          .then(data => {
            this.growler.growl(`This order is now off hire, any required invoices will be raised shortly.`, "Off Hire", "growl-info-header", "info");
            this.router.navigate(this.dataService.newLocation("orders"));
          })
          .catch(err => this.handleError(err, "Failed to set this rental off hire"))
      });
    }

    reloadRequest() {
      this.load();
    }

    customerStatus() {
      return this.service.customerStatus(this.record);
    }
    driverStatus() {
      return
    }
    vehicleStatus() {
      return
    }
    contractValid() {
      return this.service.contractValid(this.record);
    }

}
