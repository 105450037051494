import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CoreComponent } from '../base/base.component';
import { ContactSelectDialogComponent } from '../contactselectdialog/contactselectdialog.component';
import {saveAs as importedSaveAs} from "file-saver";
import { DocumentSelectComponent } from '../documentselect/documentselect.component';

@Component({
  selector: 'email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent extends CoreComponent {

  @Input('customerId') customerId: number;
  @Input('customerInternalReference') customerInternalReference: string;
  @Input('subject') subject: string;
  @Input('content') content: string;
  @Input('enableSendButton') enableSendButton: boolean = true;
  @Input('enableAttachments') enableAttachments: boolean = true;
  @ViewChild("contactselect") contactselect: ContactSelectDialogComponent;
  @ViewChild("documents") documents: DocumentSelectComponent;

  public contactList: Array<any> = [];
  public attachments: Array<any> = [];

  onLoaded() { }

  public send(contentIfNotSpecified?: string) {
    if (!this.contactList || this.contactList.length == 0) {
      this.growler.growl("Please choose the recipient(s) for your email.", "Information", "growl-info-header", "info");  
      return;        
    }

    if (contentIfNotSpecified && !this.content) {
      this.content = contentIfNotSpecified;
    }

    const newEmail = {
      recipients: this.contactList.map(r => { return { 
        contactId: r.id, 
        customerId: this.customerId,
        name: r.firstName + ' ' + r.lastName, 
        email: r.emailAddress
      } }),
      attachments: this.attachments,
      subject: this.subject,
      content: this.content
    }
    
    this.dataService.save(newEmail, "email/queue").subscribe(
      data => {
        this.growler.growl(`The email has been submitted and will be sent shortly.`, "Email queued", "growl-info-header", "info");  
      },
      error => this.handleApiError(error, `Failed to send email`)
    )
  }

  public clearContacts() {
    this.contactList.splice(0, this.contactList.length);
  }

  public clearAttachments() {
    this.attachments.splice(0, this.attachments.length);
  }

  public addAttachment(attachment: any, canDelete: boolean = true) {
    /*
    documentId:10
    filename:"Quotation.pdf"
    id:10
    internalReference:"b58112eb-d103-4775-9b77-8d39295fedb3"
    mimeType:"application/pdf"
    path:"./documents/quotation/b58112eb-d103-4775-9b77-8d39295fedb3"
    serverFilename:"c7283f68-da77-4858-b337-0c4f37a258df"
    */
    attachment.canDelete = canDelete;

    let existing = this.attachments.find(a => a.serverFilename == attachment.serverFilename);
    if (existing) {
      existing = attachment;
      return;
    }
    this.attachments.push(attachment);
  }

  private contactsChanged(contactList: any) {
    this.contactList = contactList;
  }

  private removeContact(contact: any) {
    const index = this.contactList.findIndex(x => x.id == contact.id);
    this.contactList.splice(index, 1);
  }

  private removeAttachment(attachment: any) {
    const index = this.attachments.findIndex(x => x.serverFilename == attachment.serverFilename);
    this.attachments.splice(index, 1);
  }

  private viewAttachment(e: any) {
    const doc = this.attachments.find(x => x.serverFilename == e.target.title);
    let tab =  window.open();
    this.dataService.downloadDocument(doc.documentId, doc.filename, doc.mimeType)
      .subscribe(data => {
          const openInBrowser = this.dataService.openInBrowser(doc.mimeType);
          if (openInBrowser) {
            tab.location.href = URL.createObjectURL(data);
          }
          else {
            tab.close();
            importedSaveAs(data, doc.filename);
          }
        });
  }

  public openContactSelect() {
    this.contactselect.show("Select contacts to email", "user-profile-image", this.customerId, this.contactList);
  }

  public selectDocuments() {
    this.documents.show("Customer documents", "customer", this.customerInternalReference, "document-image");
  }

  public documentSelected(item) {
    console.log(item);
    this.addAttachment(item, true);
  }

}
