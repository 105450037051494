import { Component, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'contactselectdialog',
  templateUrl: './contactselectdialog.component.html',
  styleUrls: ['./contactselectdialog.component.scss']
})
export class ContactSelectDialogComponent extends GridSelectComponent {

  customerId: number = undefined;
  dataColumns = [ "icon", "firstName" ];
  title = "Select contact...";
  modalClass = 'hide-modal';
  avatarClass = 'document-image';
  selectedContacts = [];
  
  onLoaded() {
    if (this.selectedContacts && this.selectedContacts.length > 0) {
      this.dataSource.data.forEach(x => {
        if (this.selectedContacts.find(c => c.id == x.id)) {
          x.icon = "check_circle";
        }
      });
    }
  }

  public show(title: string, avatarClass: string, customerId: number, selectedContacts: Array<number>) {
    if (!customerId) {
      this.growler.growl("Please choose a customer first.", "Information", "growl-info-header", "info");  
      return;        
    }
    this.customerId = customerId;
    this.title = title;
    this.avatarClass = avatarClass || "document-image";
    this.modalClass = "show-modal";
    this.selectedContacts = selectedContacts;
    this.loadContacts();
  }
  public hide() {
    this.doUpdateRecord(this.selectedContacts, true);
    this.modalClass = "hide-modal";
  }  
  private loadContacts() {
    this.collectionName = (this.customerId) ? `contacts/customer/${this.customerId}` : undefined;
    this.updateCollection();
  }

  select(row) {
    const deselect = (row.icon === "check_circle")
    if (deselect) {
      const index = this.selectedContacts.findIndex(x => x.id == row.id);
      this.selectedContacts.splice(index, 1);
      row.icon = "account_circle";
    }
    else if (row.emailAddress) {
      this.selectedContacts.push(row);
      row.icon = "check_circle";
    }
    else {
      this.growler.growl("This contact does not have an email address assigned. Please update the contact entry if you want to send email to this person (use the customer editor).", "Warning", "growl-info-header", "info");  
    }
  }

}
