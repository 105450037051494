import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'dialog-yesno',
  template: `
  <h1 mat-dialog-title>{{ data.title || "Question" }}</h1>

  {{ data.question }}
  
  <br/><br/>

  <mat-form-field>
    <mat-label>Please specify the date</mat-label>
    <input name="thedate" [(ngModel)]="thedate" matInput [matDatepicker]="picker" required placeholder="Please specify date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Time</mat-label>
    <input name="thetime" type="time" required [(ngModel)]="thetime" matInput placeholder="Enter time">
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-button (click)="confirmSelection()" color="primary">
    <mat-icon>done</mat-icon> Yes
    </button>
    <button mat-button (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>  No
    </button>
  </div>  `
})
export class DialogDateComponent {

  thedate: Date = undefined;
  thetime: any = undefined;

  constructor(
    public dialogRef: MatDialogRef<DialogDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
        if (data && data.initialDate) {
            this.thedate = data.initialDate;
        }
        else {
            this.thedate = new Date();
        }

        this.thetime = this.thedate.getHours() + ':00';
  }

  confirmSelection() {
    if (!this.thedate || !this.thetime) {
        return false;
    }
    
    const tmSplit = this.thetime.split(':');
    this.thedate.setHours(tmSplit[0], tmSplit[1], 0);

    this.dialogRef.close(this.thedate);
  }

}