import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpModule, RequestOptions, XHRBackend } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';

import { AppRoutingModule } from './app-routing.module';
import { HttpService } from './services/http.service';
import { UserService } from './services/user.service';
import { DataService } from './services/data.service';
import { LoaderService } from './services/loader.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CoreModule } from './core/core.module';

import { BaseComponent, CoreComponent, GridSelectComponent } from './base/base.component';

import { SnackBarErrorComponent } from './shared/snackbarerror.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { CustomersComponent } from './customers/customers.component';
import { QuotationsComponent } from './quotations/quotations.component';
import { QuotationComponent } from './quotation/quotation.component';
import { CustomerComponent } from './customer/customer.component';
import { DocumentTypesComponent } from './documenttypes/documenttypes.component';
import { DocumentSelectComponent } from './documentselect/documentselect.component';
import { ContactComponent } from './contact/contact.component';
import { CustomerSelectComponent } from './customerselect/customerselect.component';
import { VehicleSelectComponent } from './vehicleselect/vehicleselect.component';
import { LoadStatusComponent } from './base/loadstatus';
import { FormToolbarComponent } from './base/formtoolbar';
import { EmailComponent } from './email/email.component';
import { ContactSelectComponent } from './contactselect/contactselect.component';
import { OrderComponent } from './order/order.component';
import { ContactSelectDialogComponent } from './contactselectdialog/contactselectdialog.component';
import { OrdersComponent } from './orders/orders.component';
import { JournalEntry } from './journalentry/journalentry.component';

import { DialogYesNoComponent } from './shared/dialog/dialog.yesno.component';
import { DialogDateComponent } from './shared/dialog/dialog.date.component';
import { DialogJournalComponent } from './shared/dialog/dialog.journal.component';
import { DialogMapComponent } from './shared/dialog/dialog.map.component';
import { DialogVehicleComponent } from './shared/dialog/dialog.vehicle.component';

import { UsersComponent } from './users/users.component';
import { UserComponent } from './user/user.component';
import { MovementsConponent } from './movements/movements.component';
import { MovementConponent } from './movement/movement.component';
import { NewMovementConponent } from './newmovement/newmovement.component';

import { ReportComponent } from './report/report.component';
import { ReportsComponent } from './reports/reports.component';

import { InvoiceComponent } from './invoice/invoice.component';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ItemJournalComponent } from './itemjournal/itemjournal.component';
import { FleetComponent } from './fleet/fleet.component';
import { FleetOptionsComponent } from './fleet-options/fleet-options.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SnackBarErrorComponent,
    LoaderComponent,
    VehiclesComponent,
    VehicleComponent,
    CustomersComponent,
    QuotationsComponent,
    QuotationComponent,
    CustomerComponent,
    DocumentTypesComponent,
    DocumentSelectComponent,
    ContactComponent,
    LoadStatusComponent,
    FormToolbarComponent,
    EmailComponent,
    OrderComponent,
    CustomerSelectComponent,
    VehicleSelectComponent,
    ContactSelectComponent,
    ContactSelectDialogComponent,
    OrdersComponent,
    DialogYesNoComponent,
    DialogDateComponent,
    DialogJournalComponent,
    DialogMapComponent,
    DialogVehicleComponent,
    UsersComponent,
    UserComponent,
    MovementsConponent,
    MovementConponent,
    NewMovementConponent,
    JournalEntry,
    ItemJournalComponent,
    ReportComponent,
    ReportsComponent,
    InvoiceComponent,
    FleetComponent,
    FleetOptionsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpModule,
    CoreModule,
    MaterialModule,
    AppRoutingModule,
    NgxMaterialTimepickerModule.forRoot()
  ],
  exports: [
    HttpModule
  ],
  providers: [
    LoaderService,
    DataService,
    UserService,
    {
      provide: HttpService, useFactory: (backend: XHRBackend, options: RequestOptions, loader: LoaderService) => {
          return new HttpService(backend, options, loader);
        },
        deps: [XHRBackend, RequestOptions, LoaderService]
    }],
  entryComponents: [
    SnackBarErrorComponent, DialogYesNoComponent, DialogDateComponent, DialogJournalComponent,
    DialogMapComponent, DialogVehicleComponent
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
