import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from './services/loader.service';

import { NavbarComponent } from './shared/navbar/navbar.component';
import { LoaderComponent } from './shared/loader/loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLoader: boolean;
  title = 'XL Hire Application';

  constructor(
    private loaderService: LoaderService,
    private _router: Router) {
  }

  ngOnInit() {
    this._router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    };
  
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
          this._router.navigated = false;
          window.scrollTo(0, 0);
      }
    });    
  }

  /*
  prepareNotifications() {
    let vapidPublicKey = "";
    async function send() {
      console.log('Registering service worker...');
      const register = await navigator.serviceWorker.register('/worker.js', { scope: '/' });
      console.log('Service worker registered');

      console.log('Registering push subscription');
      const subscription = await register.pushManager.subscribe({ 
        userVisibleOnly: true, 
        applicationServerKey: vapidPublicKey 
      })
      console.log('Registering push subscription');

    }
  }
  
  urlBase64ToUint8Array(base64String) :any {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  */

  ngAfterContentInit() {
      this.loaderService.status.subscribe((val: boolean) => {
        // This is hacky, but wrapped in a settimeout as a way to avoid the underlying issue that causes
        // an ExpressChanged... exception
        setTimeout(() => { this.showLoader = val; }, 0);
      });
  }
}
