import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class QuotationService {

  constructor(private dataService: DataService) { }

  public generateReference() {
    let ref = 'qte-' + (new Date()).toISOString().substring(2,10).replace(/-/g , '') + '-' + (new Date()).toLocaleTimeString().replace(/:/g, '');

    return ref.toUpperCase().replace(' AM', '').replace(' PM', '');
  }


  public validate(record: any): any {
    if (!record.customerAccountId) {
        return this.dataService.errorMessage(`Please select the customer for this quotation.`, "Further information needed", "growl-info-header", "info");
    }

    if (!record.vehicleId) {
        return this.dataService.errorMessage(`Please select the vehicle for this quotation.`, "Further information needed", "growl-info-header", "info");
    }

    if (record.chargeAdminFee && !record.adminFee) {
      return this.dataService.errorMessage(`You've specified an admin fee is payable, please set the amount.`, "Further information needed", "growl-info-header", "info");
    }

    if (record.chargeDeliveryFee && !record.deliveryFee) {
      return this.dataService.errorMessage(`You've specified an delivery fee is payable, please set the amount.`, "Further information needed", "growl-info-header", "info");
    }

    if (record.chargeCollectionFee && !record.collectionFee) {
      return this.dataService.errorMessage(`You've specified an collection fee is payable, please set the amount.`, "Further information needed", "growl-info-header", "info");
    }

    /*
    if (!record.monthlyPayment || !record.initialPayment || !record.initialTerm || !record.termMonths || !record.contractMileage) {
        return this.dataService.errorMessage(`Please complete the illustration details so we can generate a full quotation.`, "Further information needed", "growl-info-header", "info");
    }
    */

    return undefined;
  }

  public tagVehicle(id: number) {
    return new Promise((resolve, reject) => {
        const record: any = { id: id };

        this.dataService.save(record, 'vehicle/tag').subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public canCreateOrder(record: any): string {
    if (!record.customerAccountId) {
        return `Please select the customer for this quotation.`;
    }

    if (!record.vehicleId) {
        return `Please select the vehicle for this quotation.`;
    }

    /*
    if (!record.monthlyPayment || !record.initialPayment || !record.initialTerm || !record.termMonths || !record.contractMileage) {
        return `Please complete the illustration details.`;
    }
    */

    if (!record.id) {
        return `Please ensure the quotation has been saved.`;
    }

    return undefined;
  }

  public save(record: any): any {
    return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        this.dataService.save(this.submittableRecord(record), "quotation").subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  // Make sure we have a clean record for saving
  private submittableRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    delete saveRecord.valid;
    return saveRecord;
  }

}
