import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit {

  userRole: string;
  roles: any = [];
  managers: any = [];

  public title: string = 'New user';

    ngOnInit() {
      this.userRole = this.userService.claims.role;
      this.loadRoleList();
      this.loadManagerList();
      this.route.params.subscribe((params: Params) => {
        let id = +params['id'];
        this.initialise(id, 'user');
      });
    }

    loadRoleList() {
      const coreRoles = [
        'accounts',
        'credit control',
        'director',
        'driver',
        'fleet admin',
        'inspector',
        'new booking',
        'sales'
      ];
      const instance = this;
      this.dataService.loadall(`user/roles`)
        .subscribe(
          (response: any[]) => {
            // What do we need to ahieve here?
            //
            const acceptableEdits = [];
            coreRoles.forEach(r => {
              acceptableEdits.push({ me: r, includes: coreRoles });
            });

            acceptableEdits.push({ me: 'supervisor', includes: coreRoles.slice(0).concat(['supervisor']) });
            acceptableEdits.push({ me: 'admin', includes: coreRoles.slice(0).concat(['supervisor', 'admin']) });
            acceptableEdits.push({ me: 'sysadmin', includes: coreRoles.slice(0).concat(['supervisor', 'admin', 'sysadmin']) });

            const profile = acceptableEdits.find(e => e.me == this.userRole);
            if (profile) {
              profile.includes.forEach(r => instance.roles.push(response.find(e => e.name == r)));
            }
          },
          (error: any) => console.log('Failed to load list of user roles')
      );
    }

    loadManagerList() {
      // TODO Ought to remove self from managers list
      this.dataService.loadall(`users`)
        .subscribe(
          (response: any[]) => { this.managers = response; }
      );
    }

    onRecordChanged(quotation: any) {

    }

    changeRole(e: any) {
      if (e.value) {
        this.record.canAuthoriseRequests = (e.value == "sysadmin" || e.value == "admin" || e.value == "supervisor");
        this.record.canAuthoriseZeroValueOrder = (e.value == "sysadmin" || e.value == "admin");
        this.record.canAccessDocumentTypes = (e.value == "sysadmin" || e.value == "admin");
        this.record.canAccessUsers = (e.value == "sysadmin" || e.value == "admin" || e.value == "supervisor");
        this.record.canAddVehicles = (e.value == "sysadmin" || e.value == "admin" || e.value == "supervisor");
      }
    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(
          `Please ensure you have completed all of the required fields.`,
          `Information missing`,
          `growl-error-header`,
          `info`
        );
        return false;
      }
      let msg = '';
      if (this.record.newPassword) {
        if (this.record.newPassword.length < 6) {
          msg = 'If changing the password, it must be at least 6 characters.';
        }
        else if (this.record.newPassword !== this.record.confirmPassword) {
          msg = 'Please correctly confirm the new password.';
        }
        if (msg) {
          this.growler.growl(msg, "Information missing", "growl-error-header", "info");
          return false;
        }
      }
      this.dataService.save(this.record, "user")
        .subscribe(
          (response: any[]) => {
            this.growler.growl(`The user has been saved.`, "Save complete", "growl-info-header", "info");
            this.router.navigate(this.dataService.newLocation("users"));
          },
          (error: any) => this.handleError(error, "Failed to save user")
        );
    }

    onLoaded() {
      this.entity = 'user';
      this.title = "Editing user";
    }

    reloadRequest() {
      this.load();
    }
}
