import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
    selector: 'snack-bar-error-component',
    templateUrl: './snackbarerror.component.html',
    styleUrls: ['./snackbarcustom.component.scss']
})
export class SnackBarErrorComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { 

    }
    
}