import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';
import { DataService } from '../../services/data.service';
import { VehicleService } from '../../services/vehicle.service';
import { GrowlerService } from '../../core/growler/growler.service';

@Component({
  selector: 'dialog-vehicle',
  templateUrl: './dialog.vehicle.component.html'
})
export class DialogVehicleComponent {

  record: any = { };
  originalRecord: any = { }
  private service: VehicleService;

  constructor(public dataService: DataService, protected growler: GrowlerService, 
    public dialogRef: MatDialogRef<DialogVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.record = data;
      Object.assign(this.originalRecord, this.record);
  }

  public updateRecord() {
    if (!this.service) {
      this.service = new VehicleService(this.dataService);
    }

  }

}