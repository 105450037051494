import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GrowlerService } from '../core/growler/growler.service';
import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';
import { AddressHelper } from '../model/address';
import { DocumentSelectComponent } from '../documentselect/documentselect.component';
import { ContactComponent } from '../contact/contact.component';
import { ItemJournalComponent } from '../itemjournal/itemjournal.component';

@Component({
  selector: 'customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private growler: GrowlerService,
    private userService: UserService,
    private dataService: DataService) { }

    private status: any = {
      collection: "customers",
      entity: "Customer",
      loadmessage: "",
      loadfailed: false,

      setFailed(reason: string) {
        this.loadmessage = reason;
        this.loadfailed = true;
      }
    }

    id: any;
    record: any = {};
    contacts: any = [];
    salesReps: any = [];
    contactChangedSubscription: Subscription;
    
    @ViewChild("documents") documents: DocumentSelectComponent;
    @ViewChild("contact") contact: ContactComponent;
    @ViewChild("journal") journal: ItemJournalComponent;
    

    ngOnInit() {
      this.dataService.loadall(`users`)
        .subscribe(
          (response: any[]) => { this.salesReps = response; },
          (error: any) => console.log("Failed to load list of sales reps")
      );
      this.route.params.subscribe((params: Params) => {
        if (this.contacts && this.contacts.length > 0) {
          this.contacts.splice(0, this.contacts.length);
        }
        this.id = +params['id'];
        this.load();
      });
      this.contactChangedSubscription = this.contact.contactChanged
        .subscribe((contact: any) => {
          this.loadContacts();
        },
        (err: any) => console.log(err));
    }
    ngOnDestroy() {
      this.contactChangedSubscription.unsubscribe();
    }
    
    load() {
      this.status.loadfailed = false;
      this.status.loadmessage = `Loading ${this.status.entity} details...`;
      if (!this.id || this.id === 0) {
        this.status.loadmessage = '';
        this.record = {};
        this.record.internalReference = this.dataService.newGuid();
        return false;
      }
      this.dataService.load(this.id, this.status.entity).subscribe(
        data => { 
          this.status.loadmessage = '';
          this.record = data;
          this.loadContacts();
        },
        error => { 
          this.status.setFailed(`Details for the ${this.status.entity} could not be retrieved`);
          this.handleApiError(error, `Failed to retrieve ${this.status.entity}`);
        }
      )
    }
    loadContacts() {
      this.dataService.loadall(`contacts/customer/${this.record.internalReference}`)
        .subscribe((response: any[]) => { 
          this.contacts = response; 
          let helper = new AddressHelper();
          this.contacts.forEach(element => {
            element.address = helper.formatAddress(element, "(default customer address)");
          });
        },
        (error: any) => console.log("Failed to load contact list")
      );
    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");  
        return false;
      }
      if (!this.contacts || this.contacts.length < 1) {
        this.growler.growl(`At least one contact must be added for this customer. Please do this before saving.`, "Contact(s) required", "growl-error-header", "info");  
        return false;
      }
      this.dataService.save(this.record, this.status.entity).subscribe(
        data => {
          this.growler.growl(`The ${this.status.entity} has been saved.`, "Save complete", "growl-info-header", "info");  
          this.router.navigate(this.dataService.newLocation(this.status.collection));
        },
        error => this.handleApiError(error, `Failed to save ${this.status.entity}`)
      )
    }
    handleApiError(err, title) {
      if (err.loginRedirectRequired && err.loginRedirectRequired === true) {
        this.userService.logout();
        this.growler.growl("Your login session has expired, you will need to log again in to continue.", "Warning", "growl-info-header", "info");  
        this.router.navigate(["login"]);
      }
      else {
        this.growler.growl(err.detail || "An unknown error occured", title, "growl-error-header", "error");  
      }
    }
    resetForm() {
      this.router.navigate(this.dataService.newLocation(this.status.collection));
    }
    generateAccountNumber(force) {
      if (force) {
        let ref = this.assignAccountNumber();
        if (ref)
          this.record.accountReference = ref;
        else
          this.growler.growl("Please enter the customer name, with at least two characters to generate an account referemce.", "Warning", "growl-info-header", "info");  
      }
      else if ((!this.record.id || !this.record.accountReference) && this.record.customerName) {
        let ref = this.assignAccountNumber();
        this.record.accountReference = ref || this.record.accountReference;
      }
    }
    assignAccountNumber() {
      let nameSplit = this.record.customerName.split(' ');
      let accountReference = "";
      if (nameSplit.length > 1) {
        accountReference = nameSplit[0].substring(0,1);
        accountReference += nameSplit[1].substring(0,1);
      }
      else if (nameSplit.length == 1) {
        accountReference = nameSplit[0].substring(0,2);
      }
      if (!accountReference || accountReference.length != 2) {
        return undefined;
      }
      const dateStr = (new Date()).toISOString().substring(2,10).replace(/-/g , '');
      return `${accountReference}${dateStr}`;
    }


    showDocuments() {
      this.documents.show("Customer documents", "customer", this.record.internalReference, "document-image");
    }

    newContact() {
      this.contact.create(this.record.internalReference);
    }
    selectContact(contact) {
      this.contact.edit(contact);
    }

    donothing(e) {
      e.stopPropagation();
    }

    signupSummary() {
      if (!this.record.type) {
        return "(please specify customer type)";
      }
      return `Customer type: ${this.record.type}`;
    }
    addressSummary() {
      let helper = new AddressHelper();
      return helper.formatAddress(this.record, "(please specify address)");
    }
    contactSummary() {
      let c = (this.contacts) ?  this.contacts.length : 0;
      if (c==0) return '(please add contacts)'
      else if (c==1) return `Contact: ${this.contacts[0].firstName} ${this.contacts[0].lastName}`
      else return `${c} contacts...`;
    }
}
