import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'vehicleselect',
  templateUrl: './vehicleselect.component.html',
  styleUrls: ['./vehicleselect.component.scss']
})
export class VehicleSelectComponent extends GridSelectComponent implements OnInit {

  collectionName = "fleet";
  dataColumns = [ "vrm", "filter" ];

  ngOnInit() {
    this.initialise(this.id, "fleet");
  }

  onLoaded(data:any, source?: string) {
  }
  
  select(row) {
    this.updateId(row.id);
  }
}
