import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'load-status',
  template: `
    <div class="form-main" *ngIf="message">
        <div *ngIf="!failed">{{message}}</div>
        <div *ngIf="failed" class="warning-area">
            <mat-icon class="nudge">info</mat-icon><span class="message-title">An error occured</span>
            <div>
                {{message}}
            </div>
            <button mat-raised-button color="accent" (click)="reloadClick()">Retry...</button>
        </div>

    </div>
  `
})
export class LoadStatusComponent {

    @Input() message: string = "Loading, please wait";
    @Input() failed: Boolean = false;
    @Output() reload = new EventEmitter<void>();

    reloadClick() {
        this.reload.emit();
    }

}
