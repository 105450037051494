import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import {
    Http,
    RequestOptions,
    RequestOptionsArgs,
    ResponseContentType,
    Response,
    Request,
    Headers,
    XHRBackend
} from '@angular/http';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import {saveAs as importedSaveAs} from "file-saver";

@Injectable()
export class HttpService extends Http {

    // This will allows us to signal that we've encountered an auth error while talking to the API
    // so should redirect to login page (bear in mind we don't want to flag up auth errors when talking
    // to other services, juse our API)
    @Output() authError: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        backend: XHRBackend,
        defaultOptions: RequestOptions,
        private loaderService: LoaderService
    ) { 
      super(backend, defaultOptions);
    }

    /*
    Legimate errors returned by server (500 on db operations, 401/403 on auth issue etc)
    - picked up by exception handler
    - also picked up by error handler
    */

    get(url: string, options?: RequestOptionsArgs, includeLoader: boolean = true): Observable<any> {
        if (includeLoader) {
            this.showLoader();
        }
        return super.get(this.getFullUrl(url), this.requestOptions(options))
            .do(
                (res: Response) => { this.onSuccess(res); }, 
                (error: any) => { this.onError(error);
            })
            .catch(this.onCatch)
            .finally(() => { this.onEnd(includeLoader); });
    }

    post(url: string, body: any, options?: RequestOptionsArgs, includeLoader: boolean = true): Observable<any> {
        if (includeLoader) {
            this.showLoader();
        }
        return super.post(this.getFullUrl(url),body, this.requestOptions(options))
            .do(
                (res: Response) => { this.onSuccess(res); }, 
                (error: any) => { this.onError(error);
            })
            .catch(this.onCatch)
            .finally(() => { this.onEnd(includeLoader); });
    }

    downloadFile_old(url: string, key: string, filename: string, mimeType?: string) {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        let options = new RequestOptions({responseType: ResponseContentType.Blob });
        options.headers = new Headers();
        options.headers.append('x-access-token', token );
        options.headers.append('applicationkey', "f2326081-fcc3-4d70-925a-8fe463fca297" );
        super.get(this.getFullUrl(url) + '/' + key, options)
            .subscribe((dataReceived: any) => {
                let blob = new Blob([dataReceived._body]);
                importedSaveAs(blob, filename);
                return blob;
            },
            (err:any) => this.onError(err),
            () => console.log('Complete')
          );
    }    

    downloadFile(url: string, key: string, filename: string, mimeType) : Observable<any> {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        let options = new RequestOptions({responseType: ResponseContentType.Blob });
        options.headers = new Headers();
        options.headers.append('x-access-token', token );
        options.headers.append('applicationkey', "f2326081-fcc3-4d70-925a-8fe463fca297" );
        return super.get(`${this.getFullUrl(url)}/${key}`, options)
            .map((res: any) => { return new Blob([res._body], { type: mimeType, }); });
    }    
    
    uploadFile(url: string, file: any, details: any): Observable<any> {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        let options = new RequestOptions();
        options.headers = new Headers();
        //options.headers.append('Content-Type', 'application/octet-stream');
        //options.headers.append('Upload-Content-Type', file.type);
        options.headers.append('x-access-token', token );
        options.headers.append('applicationkey', "f2326081-fcc3-4d70-925a-8fe463fca297" );

        const formData = new FormData();
        //for (const file of files) { formData.append("uploadfile", file); }
        formData.append("uploadfile", file);
        for (const v in details) { if (details[v]) formData.append(v, details[v]); }

        return super.post(this.getFullUrl(url), formData, options)
            .do(
                (res: Response) => { this.onSuccess(res); }, 
                (error: any) => { this.onError(error);
            })
            .catch(this.onCatch)
            .finally(() => { });
    }
    
    private requestOptions(options?: RequestOptionsArgs): RequestOptionsArgs {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;

        if (!options) options = new RequestOptions();
        if (!options.headers) options.headers = new Headers();
        options.headers.append('Content-Type', 'application/json');
        options.headers.append('x-access-token', token );
        options.headers.append('applicationkey', "f2326081-fcc3-4d70-925a-8fe463fca297" );

        return options;
    }

    private getFullUrl(url: string): string {
        return `https://legacyapi.seabeckconsulting.co.uk${url}`;
    }

    private onCatch(error: any, caught: Observable<any>): Observable<any> {
        let errorResponse = this.processError(error);
        console.error(`${(new Date()).toLocaleString()}  onCatch error:: ${JSON.stringify(errorResponse, null, 4)}`);
        return Observable.throw(errorResponse);
    }

    private onSuccess(res: Response): void {
    }

    private onError(error: any): void {
        console.error(`${(new Date()).toLocaleString()}  onError handler:: ${JSON.stringify(error, null, 4)}`);
        this.processError(error);
    }

    private processError(error: any): any {
        if (error.processod) {
            console.error(`${(new Date()).toLocaleString()}  processError, aborting already processed`);
            return error;
        }
        // Intention here is to produce a sanitised error that we can show to an end user. The http service
        // itself will deal with the raw error
        const errorBody = error["_body"];
        let errMessage: string = (errorBody)
            ? (errorBody.message || errorBody)
            : (error.detail || error.statusText);

        let errorResponse: any = { 
            "status" : (error && error.status) || 500,
            "processed": true,
            "loginRedirectRequired": (error.status == 401 || error.status == 403),
            "message": (error.statusText == "Forbidden") ? "Access not allowed." : error.statusText,
            "detail": (errMessage == "Failed to validate authorisation token.") ? "Your login session has expired, you will need to log in again to continue." : errMessage
        }
        console.error(`${(new Date()).toLocaleString()}  processError:: ${JSON.stringify(errorResponse, null, 4)}`);
        if (errorResponse.loginRedirectRequired) {
            this.authError.emit(errorResponse);
            /*
            let _user = localStorage.getItem('currentUser');
            if (_user) {
                localStorage.removeItem('currentUser');            
            }
            this.router.navigate(['/login', error.detail ]);
            */
        }
        return errorResponse;
    }

    private onEnd(includeLoader: boolean): void {
        if (includeLoader) {
            this.hideLoader();
        }
    }

    private showLoader(): void {
        this.loaderService.display(true);
    }

    private hideLoader(): void {
        this.loaderService.display(false);
    }
}