import { Component, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { FleetOptionsComponent } from '../fleet-options/fleet-options.component';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent extends GridSelectComponent implements OnInit, OnDestroy {

  collectionName = 'vehicles';
  dataColumns = [ 'vrm', 'filter', 'location', 'status'];
  fullEditSubscription: Subscription;

  public vehicleFilter = new FormControl();
  public statusFilter = new FormControl();
  @ViewChild('fleetOptions') fleetOptions: FleetOptionsComponent;

  defaultFilter: any = undefined;
  activeFilter: any = { statusFilter: [] };
  statusMapping: any = [];

  ngOnInit() {
    this.statusMapping = this.dataService.vehicleStatusList();

    this.vehicleFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.vehicleFilter = value;
        this.applyVehicleFilter(this.activeFilter.vehicleFilter, this.activeFilter.statusFilter);
      })
    this.statusFilter.valueChanges
      .subscribe(value => {
        this.activeFilter.statusFilter = value;// ?  value.replace(/\s+/g, '').split(',') : [];
        this.applyVehicleFilter(this.activeFilter.vehicleFilter, this.activeFilter.statusFilter);
      })
    this.fullEditSubscription = this.fleetOptions.fullEditRequested
      .subscribe((id: any) => {
        this.dataService.returnUrl = "fleet";
        this.router.navigate(["vehicle", id]);
      },
      (err: any) => console.log(err));

  }
  ngOnDestroy() {
    this.fullEditSubscription.unsubscribe();
  }
  onLoaded() {

  }
  select(row) {
    this.fleetOptions.edit(row);
  }
  

  selectOrder(e: any,row:any) {
    e.stopPropagation();
    this.dataService.returnUrl = 'vehicles';
    this.router.navigate(['order', row.orderId]);
  }

  applyVehicleFilter(vehicleFilter: string, statusFilter: string) {

    function hasMatchingProperty(data: any, filter: string, propertyName: string, propertyValue: string) {
      const activeFilterObject: any = JSON.parse(filter);
      const filterValue = activeFilterObject[propertyValue];
      if (!filterValue || filterValue.length == 0) {
          return true;
      } else {
        let dataValue = data[propertyName];
        if (propertyName === 'status') {
          if (!dataValue) {
            return false;
          }
          dataValue = dataValue.replace(/\s+/g, '');
          return filterValue.indexOf(dataValue.toLowerCase()) > -1;
        }
        else
          return (dataValue && dataValue.toLowerCase().indexOf(filterValue) != -1);
      }
    }

    if (!this.dataSource) {
      return;
    }

    if (vehicleFilter || statusFilter) {
      if (!this.defaultFilter) {
        // Ensure we have the default filter to roll back to
        this.defaultFilter = this.dataSource.filterPredicate;
      }

      let filterObject = JSON.stringify({
        value: vehicleFilter ? vehicleFilter.toLowerCase() : undefined,
        status: statusFilter ? statusFilter : []
      });

      this.dataSource.filterPredicate = (data: any, filter: string) => hasMatchingProperty(data, filter, "filter", "value") && hasMatchingProperty(data, filter, "status", "status");
      this.dataSource.filter = filterObject;
    }
    else {
      // Roll back to the original
      this.dataSource.filterPredicate = this.defaultFilter;
      this.dataSource.filter = undefined;
    }
  }

}
