import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '../../services/user.service';
import { HttpService } from '../../services/http.service';
import { DataService } from '../../services/data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

declare var require: any;

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  authStateSubscription: Subscription;
  authErrorSubscription: Subscription;
  version: string;
  reportList: any = [];

  constructor(
    private router: Router,
    public userService: UserService,
    private httpService: HttpService,
    private dataService: DataService,
    public dialog: MatDialog) { }

  ngOnInit() {
    const { version: appVersion } = require('../../../../package.json');
    this.version = appVersion;
    this.loadReports();
    this.authStateSubscription = this.userService.authChanged
          .subscribe((loggedIn: boolean) => {
            console.log(`nav:: Detected user login state change = ${loggedIn}`);
            this.loadReports();
          },
          (err: any) => console.log(err));

          /*
      this.authErrorSubscription = this.httpService.authError
          .subscribe(() => {
            console.log(`nav:: Detected auth error, should redirect`);
            this.logout();
          },
          (err: any) =>
            console.log('nav:: trapped error in subscriber' + err)
          );
          */
  }

  loadReports() {

    if (this.userService.isAuthenticated===true) {
      this.dataService.loadall(`reports`)
        .subscribe(
          (response: any[]) => { this.reportList = response; },
          (error: any) => { }
        );
      }
  }


  ngOnDestroy() {
      this.authStateSubscription.unsubscribe();
  }

  home() {
    this.router.navigate(['/home']);
  }
  vehicles() {
    this.router.navigate(['/vehicles']);
  }
  newVehicle() {
    this.router.navigate(['/vehicle', 0]);
  }
  customers() {
    this.router.navigate(['/customers']);
  }
  newCustomer() {
    this.router.navigate(['/customer', 0]);
  }
  newUser() {
    this.router.navigate(['/user', 0]);
  }
  newJournal() {
    this.router.navigate(['/newjournal']);
    /*
    let dialog = this.dialog.open(DialogJournalComponent, {
      data: { title: "Journal", question: 'Here is where we can add a new journal entry' },
    });
    dialog.afterClosed().subscribe(result => {
      console.log(`dialog closed > ${result}`);
    });
    */
  }
  orders() {
    this.router.navigate(['/orders']);
  }
  quotations() {
    this.router.navigate(['/quotations']);
  }
  newQuotation() {
    this.router.navigate(['/quotation', 0]);
  }
  documentTypes() {
    this.router.navigate(['/documenttypes']);
  }
  users() {
    this.router.navigate(['/users']);
  }
  logout() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }
  newInvoice() {
    this.router.navigate(['/invoice']);
  }
  newReport() {
    this.router.navigate(['/report']);
  }
  reports() {
    this.router.navigate(['/reports']);
  }
  movements() {
    this.router.navigate(['/movements']);
  }
  openReport(url: string) {

    //const url = e.getAttribute"report-url");
    const tab =  window.open();
    tab.location.href = url;

    //console.log(e);
  }

}
