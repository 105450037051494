import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';
import { GrowlerService } from '../core/growler/growler.service';

@Component({
  selector: 'quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent implements OnInit {
  //displayedColumns = [ "icon", "registration", "customerName", "type" ];
  displayedColumns = [ "registration", "customerName", "type" ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private growler: GrowlerService,
    private dataService: DataService,
    private userService: UserService) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.loadall("quotations")
        .subscribe((response: any[]) => {
            if (!this.dataSource) {
              this.dataSource = new MatTableDataSource(response);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          },
          (error: any) => this.handleApiError(error, "Failed to load quotations")
        );
  }
  handleApiError(err, title) {
    if (err.loginRedirectRequired && err.loginRedirectRequired === true) {
      this.userService.logout();
      this.growler.growl("Your login session has expired, you will need to log again in to continue.", "Warning", "growl-info-header", "info");
      this.router.navigate(["login"]);
    }
    else {
      this.growler.growl(err.detail || "An unknown error occured", title, "growl-error-header", "error");
    }
  }

  selectRow(row: any) {
    this.dataService.returnUrl = "quotations";
    this.router.navigate(["quotation", row.id]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
