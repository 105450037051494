import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CommentStmt } from '@angular/compiler';
import helper from '../shared/helper';

@Injectable()
export class OrderService {

  constructor(private dataService: DataService) { }

  public validate(record: any): any {
    if (!record.vehicleId) {
        return this.dataService.errorMessage(`Please select the vehicle for this order.`, "Further information needed", "growl-info-header", "info");
    }

    /*
    if (!record.monthlyPayment || !record.initialPayment || !record.initialTerm || !record.termMonths || !record.contractMileage) {
        return this.dataService.errorMessage(`Please complete the contact pricing details for this order.`, "Further information needed", "growl-info-header", "info");
    }
    */

    return undefined;
  }

  public generateReference() {
    const ref = 'ord-' + (new Date()).toISOString().substring(2,10).replace(/-/g , '') + '-' +
                      (new Date()).toLocaleTimeString().replace(/:/g, '');

    return ref.toUpperCase().replace(' AM', '').replace(' PM', '');
  }

  public save(record: any): any {
    return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        this.dataService.save(this.submittableRecord(record), 'order').subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public tagVehicle(id: number) {
    return new Promise((resolve, reject) => {
        const record: any = { id: id };

        this.dataService.save(record, 'vehicle/tag').subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public onhire(record:any, date: Date) {
    return new Promise((resolve, reject) => {
        const saveRecord: any = {
            id: record.id,
            date: date,
            vehicleId: record.vehicleId
        }

        this.dataService.save(saveRecord, 'order/onhire').subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public offhire(record:any, date: Date) {
    return new Promise((resolve, reject) => {
        const saveRecord: any = {
            id: record.id,
            date: date,
            vehicleId: record.vehicleId
        }

        this.dataService.save(saveRecord, 'order/offhire').subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  // Make sure we have a clean record for saving
  private submittableRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    saveRecord.requiredDate = helper.dbDate(saveRecord.requiredDate);
    delete saveRecord.currentSpend;
    delete saveRecord.valid;
    return saveRecord;
  }

  public customerStatus(record: any) {
      if (!record.customerAccounrId) {
          return { invalid: true, message: "No customer selected" }
      }
      else if (record.monthlySpendLimit && ((record.currentSpend + record.monthlyPayment) > record.monthlySpendLimit)) {
        return { invalid: true, message: "This order will exceed the monthly spend limit for the customer" }
      }
      return  { }
  }
  public contractValid(record: any) {
    if (!record.monthlyPayment || !record.initialPayment || !record.initialTerm || !record.termMonths || !record.contractMileage) {
        return false;
    }
    return true;
  }

}
