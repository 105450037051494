import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class MovementService {

  constructor(private dataService: DataService) { }

  public validate(record: any): any {
    return undefined;
  }

  public save(record: any): any {
    return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        this.dataService.save(this.submittableRecord(record), "movement").subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }
  
  private submittableRecord(record: any): any {
    /*
	  @username nvarchar(100)
   ,@internalReference nvarchar(50) = null
   ,@driverUsername nvarchar(100) = null -- Can be created without being actually allocated
   ,@orderId int = null
   ,@contactId int = null
   ,@vehicleId int = null
   ,@jobReference nvarchar(50)
   ,@due datetime -- Requested time as opposed to an actual timeslot booking
   ,@status nvarchar(50)
   ,@startMileage int
   ,@endMileage int
   ,@movementType nvarchar(50)
   ,@scheduledTimeslotStart datetime = null
   ,@scheduledTimeslotEnd datetime = null
   ,@fromCompoundLocation nvarchar(50) = null
   ,@toCompoundLocation nvarchar(50) = null
   ,@fromAddress1 nvarchar(50) = null
   ,@fromAddress2 nvarchar(50) = null
   ,@fromAddress3 nvarchar(50) = null
   ,@fromAddress4 nvarchar(50) = null
   ,@fromAddress5 nvarchar(50) = null
   ,@fromPostcode nvarchar(10) = null
   ,@toAddress1 nvarchar(50) = null
   ,@toAddress2 nvarchar(50) = null
   ,@toAddress3 nvarchar(50) = null
   ,@toAddress4 nvarchar(50) = null
   ,@toAddress5 nvarchar(50) = null
   ,@toPostcode nvarchar(10) = null

    */
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    delete saveRecord.canSelectContact;
    delete saveRecord.canSelectCustomer;
    delete saveRecord.canSelectVehicle;
    delete saveRecord.contact;
    delete saveRecord.customerAccountId;
    delete saveRecord.orderReference;
    return saveRecord;
  }

}
