import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import helper from '../shared/helper';

@Injectable()
export class JournalService {

  constructor(private dataService: DataService) { }

  public validate(record: any): any {
  
    return undefined;
  }

  public save(record: any): any {
    return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        this.dataService.save(this.newRecord(record), "journal").subscribe(
            data => resolve(data),
            error => reject(error)
        )
    });
  }

  public update(record: any): any {
    /*
    @internalReference nvarchar(50)
   ,@dateActionRequired nvarchar(50) = null
   ,@text [nvarchar](500) = null
   ,@forUsername nvarchar(100) = null
   ,@contactId [int] = null
   ,@customerInternalReference nvarchar(50) = null
   ,@priority int = 4
   */
  return new Promise((resolve, reject) => {
        const validError = this.validate(record);
        if (validError) {
            return reject(validError);
        }

        if (record.willAction) {
          this.dataService.save(this.updateRecord(record), "journal/action").subscribe(
            data => resolve(data),
            error => reject(error)
          )
        }
        else {
          this.dataService.save(this.updateRecord(record), "journal/update").subscribe(
              data => resolve(data),
              error => reject(error)
          )
        }
    });
  }

  // Make sure we have a clean record for saving
  private newRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    delete saveRecord.internalReference;
    if (saveRecord.dateRequired) {
      saveRecord.dateActionRequired = helper.dbDate(saveRecord.dateRequired);
      if (saveRecord.timeRequired) {
        saveRecord.dateActionRequired = saveRecord.dateActionRequired + ' ' + saveRecord.timeRequired;
      }
    }
    delete saveRecord.dateRequired;
    delete saveRecord.timeRequired;
    return saveRecord;
  }

  private updateRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    if (saveRecord.dateRequired) {
        saveRecord.dateActionRequired = helper.dbDate(saveRecord.dateRequired);
        if (saveRecord.timeRequired) {
          saveRecord.dateActionRequired = saveRecord.dateActionRequired + ' ' + saveRecord.timeRequired;
        }
    }
    return saveRecord;
  }

}
