import { Component, ViewChild, Input, Output, OnChanges, SimpleChanges, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GrowlerService } from '../core/growler/growler.service';
import { DataService } from '../services/data.service';
import { UserService } from '../services/user.service';
import { LoadStatusComponent } from '../base/loadstatus';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import {saveAs as importedSaveAs} from 'file-saver';

@Component({
  selector: 'core-component',
  template: ``
})
export abstract class CoreComponent implements OnChanges {

    @Input() id: any = undefined;
    @Output() recordChanged: EventEmitter<any> = new EventEmitter<any>();

    protected internalReference: string;
    public record: any = { };
    public entity: string;
    private triggerRecordChanged = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected userService: UserService,
        protected growler: GrowlerService,
        protected dataService: DataService,
        public dialog: MatDialog
    ) {
      this.dataService.http.authError.subscribe(e => {
        this.userService.logout();
        this.router.navigate(['/login', e.detail ]);
      });
    }

    protected initialise(id: any, entity) {
      this.id = id;
      this.entity = entity;
      this.load();
    }

    protected doUpdateRecord(newRecord: any, force: boolean = false) {
      this.record = newRecord;
      if (this.triggerRecordChanged || force === true) {
        this.triggerRecordChanged = false;
        this.recordChanged.emit(this.record);
      }
    }

    protected load() {
      // New record
      if (!this.id || this.id === 0) {
        if (!this.internalReference) { this.internalReference =  this.dataService.newGuid(); }
        this.doUpdateRecord({ internalReference: this.internalReference });
        return false;
      }
      // Edit record
      this.dataService.load(this.id, this.entity).subscribe(
        data => {
          this.doUpdateRecord(data);
          this.onLoaded();
        },
        error => {
          this.handleApiError(error, `Failed to retrieve ${this.entity}`);
        }
      )
    }

    ngOnChanges(changes: SimpleChanges) {
      for (const propName in changes) {
        if (propName === 'id') {
          const chng = changes[propName];
          if ((chng.currentValue != chng.previousValue) && this.entity) {
            this.id = chng.currentValue;
            this.load();
            return;
          }
        }
      }
    }

    public clear() {
      this.updateId(undefined);
    }

    public updateId(newId: any) {
      if (this.id != newId) {
        this.triggerRecordChanged = true;
        this.id = newId;
        this.load();
      }
    }

    public forceRefresh() {
      if (this.id) {
        this.triggerRecordChanged = true;
        this.load();
      }
    }

    protected abstract onLoaded(data?: any, source?: string): void;

    protected handleApiError(err, title) {
      this.growler.growl(err.detail || 'An unknown error occured', title, 'growl-error-header', 'error');
    }

    public donothing(e) {
      e.stopPropagation();
    }

    public viewDocument(documentId: number, filename: string, mimeType?: string) {
      let id = documentId;
      if (Array.isArray(documentId)) {
        id = documentId[0];
      }
      const requestedMimeType = mimeType || 'application/pdf';
      const tab =  window.open();
      this.dataService.downloadDocument(id.toString(), '', requestedMimeType)
          .subscribe(data => {
            const openInBrowser = this.dataService.openInBrowser(requestedMimeType);
            if (openInBrowser) {
              tab.location.href = URL.createObjectURL(data);
            } else {
              tab.close();
              importedSaveAs(data, filename);
            }
          }, (err: any) => {
            tab.close();
          });
    }

}


@Component({
  selector: 'base-component',
  template: ``
})
export abstract class BaseComponent extends CoreComponent {

  @ViewChild('loadStatus') loadStatus: LoadStatusComponent;

    protected load() {
      // New record
      if (!this.id || this.id === 0) {
        if (!this.internalReference) { this.internalReference =  this.dataService.newGuid(); }
        this.updateLoadStatus(``, false);
        this.doUpdateRecord({ internalReference: this.internalReference });
        return false;
      }
      // Edit record
      this.updateLoadStatus(`Loading ${this.entity} details...`, false);
      this.dataService.load(this.id, this.entity).subscribe(
        data => {
          this.updateLoadStatus(``, false);
          this.doUpdateRecord(data);
          this.onLoaded();
        },
        error => {
          this.updateLoadStatus(`Details for the ${this.entity} could not be retrieved`, true);
          this.handleApiError(error, `Failed to retrieve ${this.entity}`);
        }
      )
    }

    protected handleError(err: any, description: string) {
      if (err.title && err.type) {
        this.growler.growl(err.message, err.title, err.messageClass, err.type);
      } else {
        this.handleApiError(err, description);
      }
    }

    protected handleApiError(err, title) {
      if (err.loginRedirectRequired && err.loginRedirectRequired === true) {
        this.userService.logout();
        this.growler.growl(
          'Your login session has expired, you will need to log again in to continue.',
          'Warning',
          'growl-info-header',
          'info');
        this.router.navigate(['login']);
      } else {
        this.growler.growl(err.detail || 'An unknown error occured', title, 'growl-error-header', 'error');
      }
    }

    protected updateLoadStatus(message: string, failed: boolean) {
      setTimeout(() => {
        this.loadStatus.message = message;
        this.loadStatus.failed = failed;
      }, 150);
    }
}


@Component({
  selector: 'grid-select-component',
  template: ``
})
export abstract class GridSelectComponent extends CoreComponent  {
  protected dataSource: MatTableDataSource<any>;

  @Input() collectionName: any = undefined;
  @Input() readOnly = false;

  private childrenItialised = 0;
  private paginator: MatPaginator;
  private sort: MatSort;
  private initialised = false;

  // This method of introducing the ViewChild is to counter the fact that Angular doesn't react
  // correctly when there are ngIf directives in scope that govern display of the child
  // USE THIS WHEREEVER THIS SCENARIO OCCURS
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.childrenItialised++;
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.childrenItialised++;
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    if (this.childrenItialised === 2) {
      this.initialised = true;
      this.setDataSource();
    } else if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  private setDataSource() {
    if (this.readOnly) {
      return;
    }
    if (!this.collectionName) {
      if (this.dataSource) {
        this.dataSource = undefined;
      }
      return;
    }
    this.dataService.loadall(this.collectionName)
      .subscribe((response: any[]) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.onLoaded();
      },
        (error: any) => {
          console.error(`grid-select-component bind error ${JSON.stringify(error, null, 4)} `);
        }
      );
  }

  public updateCollection() {
    if (this.initialised) {
      if (this.dataSource) {
        this.dataSource = undefined;
      }
      this.setDataSource();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}




@Component({
  selector: 'simple-grid-component',
  template: ``
})
export abstract class SimpleGridComponent extends CoreComponent  {
  protected dataSource: MatTableDataSource<any>;

  @Input() collectionName: any = undefined;
  @Input() readOnly = false;

  private childrenItialised = 0;
  private paginator: MatPaginator;
  private sort: MatSort;
  private ready = false;
  private initialised = false;

  // This method of introducing the ViewChild is to counter the fact that Angular doesn't react
  // correctly when there are ngIf directives in scope that govern display of the child
  // USE THIS WHEREEVER THIS SCENARIO OCCURS
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.childrenItialised++;
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.childrenItialised++;
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    if (this.childrenItialised === 2) {
      this.initialised = true;
      this.setDataSource();
    } else if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  private setDataSource() {
    if (!this.ready) {
      return;
    }
    if (!this.collectionName) {
      if (this.dataSource) {
        this.dataSource = undefined;
      }
      return;
    }
    this.dataService.loadall(this.collectionName)
      .subscribe((response: any[]) => {
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.onLoaded();
      },
        (error: any) => { console.error(`simple-grid-component bind error ${JSON.stringify(error, null, 4)} `) }
      );
  }

  public updateCollection(collection: any = undefined) {
    this.ready = true;
    if (collection) {
      this.collectionName = collection;
    }
    if (this.initialised) {
      if (this.dataSource) {
        this.dataSource = undefined;
      }
      this.setDataSource();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
