import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';
import { DataService } from '../../services/data.service';
import { JournalService } from '../../services/journal.service';
import { GrowlerService } from '../../core/growler/growler.service';

@Component({
  selector: 'dialog-journal',
  templateUrl: './dialog.journal.component.html'
})
export class DialogJournalComponent {

  record: any = { };
  originalRecord: any = { }
  public userList: any = [];
  private service: JournalService;

  constructor(public dataService: DataService, protected growler: GrowlerService, 
    public dialogRef: MatDialogRef<DialogJournalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.record = data;
      this.record.willAction = false;
      // SO we can reinstate if things go wrong
      Object.assign(this.originalRecord, this.record);

      this.dataService.loadall(`users`)
        .subscribe(
          (response: any[]) => { this.userList = response; },
          (error: any) => { }
    );

  }

  public updateRecord() {
    if (!this.service) {
      this.service = new JournalService(this.dataService);
    }
    this.service.update(this.record)
      .then(data => {
        if (this.record.willAction) {
          this.record.status = 'journal-actioned';
          this.record.iconClass = 'checked-ok';
          this.record.dateActionRequiredDisplay = 'Actioned just now';
          this.record.icon = 'done';
          this.record.canAction = false;
        }
        else {
          this.record.dateActionRequiredDisplay = '(item updated, refresh required)';
          this.record.status = 'journal-updated';
        }
        this.dialogRef.close(this.record);
      })
      .catch(err => {
        this.growler.growl(err.detail || "An unknown error occured", "Unable to update journal", "growl-error-header", "error");
        this.dialogRef.close(this.originalRecord);
      })

  }

}