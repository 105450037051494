import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SnackBarErrorComponent } from '../shared/snackbarerror.component';
import { GrowlerService } from '../core/growler/growler.service';

import  { UserService } from '../services/user.service';
import  { DataService } from '../services/data.service';
import { ValidationService } from '../core/validation-service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  hideForm: boolean = false;
  activeTab: number = 0;

  constructor(
    public snackbar: MatSnackBar,
    private userService: UserService, 
    private dataService: DataService, 
    private fb: FormBuilder, 
    private growler: GrowlerService,
    private router: Router) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    //email: ['', ValidationService.emailValidator, Validators.required],
    this.registerForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      displayName: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  showLoginResult(msg: string, form: any) {
    if (form) {
      form.reset();
      form.markAsPristine();
    }
    this.growler.growl(msg, "Error", "growl-error-header", "info");  
    /*
    this.snackbar.openFromComponent(SnackBarErrorComponent, {
      data: { icon: "error_outline", title: "Error", message: msg },
      duration: 3000,
    });
    */
  }

  onSelectedTabIndexChange() {
    this.loginForm.reset();
    this.loginForm.markAsPristine();
    this.registerForm.reset();
    this.registerForm.markAsPristine();
  }

  doRedirect() {
    if (this.userService.redirectUrl) {
      const redirectUrl = this.userService.redirectUrl;
      this.userService.redirectUrl = '';
      this.router.navigate([redirectUrl]);
    } else {
        this.router.navigate(['']);
    }
  }

  doLoginComplete() {
    this.hideForm = true;
    this.dataService.traceMsg("Request static data begin");
    this.dataService.getStaticData(true).subscribe(
      data => { 
        this.dataService.traceMsg("Request static data complete");
        this.doRedirect();
      }, 
      err => { 
        this.dataService.traceMsg("Request static data failed", "warning");
        if (this.dataService.getFromLocalStorage("staticdata")) {
          this.doRedirect();
        }
        else {
          this.hideForm = false;
          this.showLoginResult('We were unable to retrieve data required by the application. Please try logging in again.', this.loginForm);
        }
      }
    );
  }

  onSubmitLogin(form: FormGroup) {
    this.userService.login({ username: form.value.username, password: form.value.password })
      .subscribe((status: boolean) => {
          if (status) {
            this.doLoginComplete();
          } else {
            // Should never get called as the error handler picks up any login failures
            this.showLoginResult('Unable to login', form);
          }
      },
      (err: any) => {
        this.showLoginResult(err.detail || err.message || 'Unable to login', form);
      });

      /*
    this.http.get('/data/vehicles/type/2063').map((res: Response) => {
        let results = res.json();
        console.log(`debug:: Results = ${JSON.stringify(results, null, 4)}`);
    })
    .catch((err:any, caught: any) => { console.log(`error:: ${JSON.stringify(err, null, 4)}`); return [] });
    */
    //this.http.post('/data/vehicles/type/2063');
  }
  onSubmitRegister(form: FormGroup) {
    if (!form.valid) {
      this.growler.growl('Please complete the account registration form.', "Error", "growl-error-header", "info");  
      return false;
    }
    if (form.value.password !== form.value.confirmPassword) {
      this.growler.growl('Please ensure you have confirmed your password correctly.', "Error", "growl-error-header", "info");  
      return false;
    }
    /*
          emailaddress: req.body.email,
          username: req.body.username || req.body.email,
          password: req.body.password,
          displayname: req.body.displayName,
    */
    const account: any = {
      username: form.value.username,
      password: form.value.password,
      email: form.value.email,
      displayName: form.value.displayName
    }
    this.userService.register(account).subscribe((status: boolean) => {
          if (status) {
            this.growler.growl('Your account is registered, please check and confirm your email to login..', "Registration", "growl-info-header", "info");  
            this.registerForm.reset();
            this.activeTab = 0;
          }
      },
      (err: any) => {
          this.growler.growl(err.detail || err.message || 'We were unable to create your account.', "Error", "growl-error-header", "info");
          this.registerForm.reset();
      });
    }

}
