import { Component, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';
import { MappingService } from '../../services/mapping.service';

declare var H: any;

@Component({
  selector: 'dialog-map',
  templateUrl: './dialog.map.component.html'
})
export class DialogMapComponent implements AfterViewInit {

  record: any = { };
  displayDestination: string = '(not specified)';
  summary: string = '';
  private mapping: MappingService;

  @ViewChild('map')
  public mapElement: ElementRef;

  constructor(public dialogRef: MatDialogRef<DialogMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.displayDestination = data.destination || '(not specified)';
      this.record = data;
  }

  getDirections(destination: string) {
    this.mapping.directions(destination, undefined)
      .then(result => {
        this.record = this.mapping.priceRouteKM(result, 25);
        this.summary = this.record.mileage ? `${this.record.mileage.toFixed(2)} miles, £${this.record.cost}` : '';
        console.log(this.record);
      })
      .catch(err => {
        console.error(JSON.stringify(err, null, 4));
      });
  }

  ngAfterViewInit() {
    this.mapping = new MappingService(H, this.mapElement, undefined, undefined);
    if (this.record.geo) {
      this.getDirections(this.record.geo);
      return;
    }
    this.mapping.geocode({ 'postalCode': this.record.destination })
    .then(result => {
      const destination = this.mapping.getGeoFromLocation(result);
      this.getDirections(destination);
    })
    .catch(err => {
      console.error(JSON.stringify(err, null, 4));
    });
  }

  public save() {
     this.dialogRef.close(this.record);
  }

}
