import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class VehicleService {

  constructor(private dataService: DataService) { }

  // Make sure we have a clean record for saving
  public submittableRecord(record: any): any {
    let saveRecord: any = {};
    Object.assign(saveRecord, record);
    this.clearArray(saveRecord.colours);
    this.clearArray(saveRecord.trims);
    this.clearArray(saveRecord.availableOptions);
    saveRecord.priceP11D = this.asFloat(saveRecord.priceP11D);
    saveRecord.discount = this.asFloat(saveRecord.discount);
    saveRecord.priceOptions = this.asFloat(saveRecord.priceOptions);
    saveRecord.netCost = this.asFloat(saveRecord.netCost);
    saveRecord.vat = this.asFloat(saveRecord.vat);
    saveRecord.roadFundLicence = this.asFloat(saveRecord.roadFundLicence);
    saveRecord.grossCost = this.asFloat(saveRecord.grossCost);
    saveRecord.discountPercent = this.asFloat(saveRecord.discountPercent);
    saveRecord.invoicePrice = this.asFloat(saveRecord.invoicePrice);
    saveRecord.firstRegFee = this.asFloat(saveRecord.firstRegFee);
    saveRecord.deliveryFee = this.asFloat(saveRecord.deliveryFee);
    delete saveRecord.colours;
    delete saveRecord.trims;
    delete saveRecord.availableOptions;
    return saveRecord;
  }

  private asFloat(v: any): any {
    return v ? parseFloat(v) : undefined;
}

  private asInt(v: any): any {
      return v ? parseInt(v) : undefined;
  }

  private clearArray(e) {
    if (!e || e.length < 1) { e = []; return false; }
    e.splice(0, e.length);
  }

}
