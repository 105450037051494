import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { DataService } from '../services/data.service';
import { GrowlerService } from '../core/growler/growler.service';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input('init') documenttype: string;
  @Output() contactChanged: EventEmitter<any> = new EventEmitter<any>();

  modalClass = 'hide-modal';
  warnClass = '';
  parentItemId: string = '';
  contact: any = {};
  documentTyoes: any = [];
  config: any = {};
  license: any = {
    verifiedSummary: "No license supplied"
  };
  drivingLicense: string = "Driving license";
  
  constructor(private dataService: DataService, private growler: GrowlerService) { 
    const y = (new Date()).getFullYear()-30;
    const date = new Date(`${y}-01-01`);
    this.config.startDate = date;
  }

  ngOnInit() {
    this.dataService.loadall("document/types")
      .subscribe((response: any[]) => { this.documentTyoes = response; });
  }

  public create(parentItemId: string) {
    this.parentItemId = parentItemId;
    this.warnClass = '';
    this.contact = {
      customerInternalReference: this.parentItemId,
      internalReference: this.dataService.newGuid()
    }
    this.modalClass = "show-modal";
    this.loadLicense();
  }

  public edit(contact: any) {
    Object.assign(this.contact, contact);
    this.warnClass = '';
    this.parentItemId = this.contact.customerInternalReference;
    this.modalClass = "show-modal";
    this.loadLicense();
  }

  public load(contactId: string) {
    this.dataService.load(contactId, "contact").subscribe(
      data => { 
        console.log(data);
        this.modalClass = "show-modal";
        this.contact = data;
        this.loadLicense();
      },
      error => {
        this.growler.growl(`A problem occured when trying to open the contact record.`, "Load failed", "growl-warn-header", "info");  
        this.hide();
      }
    )
  }
  
  public load_old(contactId: string, parentItemId: string) {
    this.parentItemId = parentItemId;
    this.modalClass = "show-modal";
  }

  public hide() {
    this.modalClass = "hide-modal";
  }

  private loadLicense() {
    this.license = { verifiedSummary: "No license supplied", supplied: false };
    this.dataService.documents("contact", this.contact.internalReference).subscribe(
        (response: any[]) => { 
          let licenseDocument = response.find(l => l.documentType == this.drivingLicense);
          if (licenseDocument) { 
            this.license = licenseDocument; 
            this.license.supplied = (licenseDocument.serverFilename);
          }
        },
        (error: any) => console.log("Failed to load documents")
    );
  }

  public save(e) {
    if (this.contactFieldsNeeded()) {
      e.stopPropagation();
      this.warnClass = "warn";
      return false;
    }
    
    let c: any = {};
    Object.assign(c, this.contact);
    delete c.address;
    if (this.license.verified) {
      c.verifyLicense = true;
    }

    this.dataService.save(c, "contact").subscribe(
      data => { 
        this.growler.growl(`The contact has been saved.`, "Save complete", "growl-info-header", "info");  
        this.contactChanged.emit(c);
        this.hide();
      },
      error => {
        this.growler.growl(`A problem occured when trying to save the contact record.`, "Save failed", "growl-warn-header", "info");  
        this.hide();
      }
    )
  }


  private contactFieldsNeeded() {
    // When entering contact details, tells user what fields are required
    let result = "";
    if (!this.contact.firstName || !this.contact.lastName) {
      result = "Name";
    }
    if (!this.contact.telephone) {
      result = result ? `${result}, telephone` : 'Telephone';
    }
    if (!this.contact.emailAddress) {
      result = result ? `${result}, email` : 'Email';
    }

    return result ? `${result} required!` : '';
  }

  public viewDrivingLicense() {
    const openInBrowser = this.dataService.openInBrowser(this.license.mimeType);
    let tab: any = undefined;
    if (openInBrowser) { tab = window.open(); }

    this.dataService.downloadDocument(this.license.id, this.license.filename, this.license.mimeType)
      .subscribe(data => {
        if (openInBrowser) {
          tab.location.href = URL.createObjectURL(data);
        }
        else {
          importedSaveAs(data, this.license.filename);
        }
      });
  }

  public saveDrivingLicense(e) {
    /*
   ,@parentType [nvarchar](100) -- eg vehicle, customer, contact, quotation, rental
   ,@parentItemId [nvarchar](100) -- eg of the parent item we associate with
   ,@documentType [nvarchar](100) = null
   ,@title [nvarchar](100)
   ,@notes [nvarchar](500) = null
   ,@username [nvarchar](100)
   ,@filename [nvarchar](500)
   ,@serverFilename [nvarchar](50)
   ,@path [nvarchar](500)
   ,@mimeType [nvarchar](250) = null
   ,@verified bit = null
    */
    const details = {
      single: true,
      verified: this.license.verified,
      documentType: this.drivingLicense,
      title: this.drivingLicense,
      notes: this.license.notes,
      parentType: "contact",
      parentItemId: this.contact.internalReference
    }
    this.dataService.processFileUpload("upload", details, e.target.files[0])
      .then(res => { 
        this.license.id = res.id;
        this.license.mimeType = res.mimeType;
        this.license.filename = res.filename;
        this.growler.growl(`The driving license for this contact has been saved on file.`, "Save complete", "growl-info-header", "info");  
        this.license.supplied = true; 
      })
      .catch(e => { console.error(`${JSON.stringify(e, null, 4)}`); });
  }

  private licenseCheckStatus(): any {
    if (this.license.verified) 
      return { className: "checked-ok", icon: "done", text: "New verification confirmed" } 
      
    if (this.license.id) {
      if (this.license.verifyDueDays < 1) {
        return { className: "", icon: "error", text: "License verification is required" }
      }
      else if (this.license.verifyDueDays < 15) {
        return { className: "", icon: "warning", text: `Verification needed again within ${this.license.verifyDueDays} days` }
      }
      else
        return { className: "checked-ok", icon: "done", text: this.license.verifiedSummary };
    }
    return { className: "", icon: "error", text: this.license.verifiedSummary };
  }

  private licenseUploadStatus(): any {
    return this.license.supplied 
      ? { className: "checked-ok", icon: "done", text: "We have a copy of the driving license on file" } 
      : { className: "", icon: "error", text: "Please upload a copy of the driving license" };
  }

  public licenseNumberStatus(): any {
    return this.contact.licenseNumber ? { className: "checked-ok", icon: "done" } :  { className: "", icon: "error" };
  }

  public donothing(e) {
    e.stopPropagation();
  }
  
}
