import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { DataService } from '../services/data.service';
import { GrowlerService } from '../core/growler/growler.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'documentselect',
  templateUrl: './documentselect.component.html',
  styleUrls: ['./documentselect.component.scss']
})
export class DocumentSelectComponent implements OnInit {

  @Input('init') documenttype: string;
  @Input("selectable") selectable: boolean = false;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  modalClass = 'hide-modal';
  avatarClass = 'document-image';
  title: string = 'Documents';
  parentType: string = '';
  parentItemId: string = '';
  fileTypes: any = [];
  
  documentUpload: any = {};
  documentTypes: any = [];
  documentsColumns = [ "icon", "title", "lastModifiedDate" ];
  documentsDataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) documentsPaginator: MatPaginator;
  @ViewChild(MatSort) documentsSort: MatSort;

  ngOnInit() {  
    this.fileTypes = this.dataService.fileTypes();
  }

  constructor(private growler: GrowlerService, private dataService: DataService) {  }

  public show(title: string, parentType: string, parentItemId: string, avatarClass: string) {
    this.title = title;
    this.parentType = parentType;
    this.avatarClass = avatarClass || "document-image";
    this.parentItemId = parentItemId;
    this.modalClass = "show-modal";
    this.dataService.load(this.parentType, `document/types`)
      .subscribe(
        (response: any[]) => { 
          this.documentTypes = response 
        },
        (error: any) => console.log("Failed to load document type list")
    );
    this.getDocumentList();
  }
  public hide() {
    this.modalClass = "hide-modal";
  }
  public upload(files: any) {
    const details = {
      title: this.documentUpload.title,
      notes: this.documentUpload.notes,
      documentType: this.documentUpload.documentType,
      parentItemId: this.parentItemId,
      parentType: this.parentType,
      mimeType: this.documentUpload.mimeType
    };

    this.dataService.uploadDocument("upload", files, details).subscribe(
      data => { 
        this.growler.growl('Your document has been saved.', "Save complete", "growl-info-header", "info"); 
        this.documentUpload = {};
        this.getDocumentList();
      },
      error => this.growler.growl(error.detail || "An unknown error occured", "Error saving document", "growl-error-header", "error")
    )
  }
  submit(e) {
    const details = {
      title: this.documentUpload.title,
      notes: this.documentUpload.notes,
      documentType: this.documentUpload.documentType,
      parentItemId: this.parentItemId,
      parentType: this.parentType
    };

    this.dataService.processFileUpload("upload", details, e.target.files[0])
      .then(res => { 
        this.growler.growl('Your document has been saved.', "Save complete", "growl-info-header", "info"); 
        this.documentUpload = {};
        this.getDocumentList();
      })
      .catch(e => { console.error(`${JSON.stringify(e, null, 4)}`); });
    
  }
  private getMimetype(file: any, signature: string): string {
    if (file.type) {
      return file.type;
    }

    let fileExtension = '';
    let e = file.name.toLowerCase().split('.');
    if (e.length > 1) { fileExtension = e[e.length-1]; }
        
    const matchingFileType = 
      this.fileTypes.find(f => f.lookupValue == fileExtension) || 
      this.fileTypes.find(f => f.value == signature);

    return (matchingFileType) ? matchingFileType.lookupType : undefined;
  }  
  selectDocument(row: any) {
    if (this.selectable) {
      this.selected.emit(row);
      this.hide();
      return;
    }
    const matchingFileType = this.fileTypes.find(f => f.lookupType == row.mimeType);
    const openInBrowser = (matchingFileType && matchingFileType.local === true);
    let tab: any = undefined;
    if (openInBrowser) { tab = window.open(); }

    this.dataService.downloadDocument(row.id, row.filename, row.mimeType)
      .subscribe(data => {
        if (openInBrowser) {
          tab.location.href = URL.createObjectURL(data);
        }
        else {
          importedSaveAs(data, row.filename);
        }
      });
  }

  emailDocument(e: any) {
    e.stopPropagation();
    this.growler.growl('Option to email the document coming soon.', "Email document", "growl-info-header", "info"); 
  }

  private getDocumentList() {
    this.dataService.documents(this.parentType, this.parentItemId)
        .subscribe((response: any[]) => { 
            response.map((item, index) => { 
              const match = this.fileTypes.find(f => f.lookupType == item.mimeType);
              item.icon = match ? match.icon : "cloud_download"; 
            });
            this.documentsDataSource = new MatTableDataSource(response);
            this.documentsDataSource.paginator = this.documentsPaginator;
            this.documentsDataSource.sort = this.documentsSort;
          },
          (error: any) => console.log("Failed to load documents")
        );
  }

  donothing(e) {
    e.stopPropagation();
  }

}
