import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { UserService } from '../services/user.service';
import { GrowlerService } from './growler/growler.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router, private growler: GrowlerService) { } 

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.isAuthenticated) { 
        return true;
    }
    
    console.log('debug:: Not authorised, redirecting...');
    if (this.userService.clearToken()) {
      this.growler.growl("Your login session has expired, you will need to log in again to continue.", "Warning", "growl-info-header", "info");  
    }
    this.userService.redirectUrl = state.url;
    this.router.navigate(['login']);
    return false;
  }

}