import { Component, OnInit, ViewChild, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { EmailComponent } from '../email/email.component';
import { InvoiceService } from '../services/invoice.service';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild("email") email: EmailComponent;

  private service = new InvoiceService(this.dataService);

  public title: string = "New invoice";

    ngOnInit() {
      this.service.init(this.record);
      this.route.params.subscribe((params: Params) => {
        this.initialise(+params['id'], 'invoice');
      });
    }

    ngAfterViewInit() {
      if (this.record.id) {
        return;
      }

      // Any initialisation of a new record
      this.service.init(this.record);
      this.record.invoiceDate = new Date();
      this.growler.growl(`Please select a customer and then add the lines applicable for this invoice.`, "Prepare invoice", "growl-info-header", "info");
    }

    public addLine() {
      if (!this.record.newLineDescription) {
        this.growler.growl(`Please enter a description for the new invoice line.`, "Information missing", "growl-error-header", "info");
        return;
      }
      if (!this.record.newLineCost) {
        this.growler.growl(`Please enter a cost for the new invoice line.`, "Information missing", "growl-error-header", "info");
        return;
      }
      this.service.addLine(this.record, this.record.newLineDescription, undefined, this.record.newLineCost);
      delete this.record.newLineDescription;
      delete this.record.newLineCost;
    }

    private removeLine(line: any) {
      this.service.removeLine(this.record, line);
    }
  
    onRecordChanged(quotation: any) {

    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");
        return false;
      }
      this.service.save(this.record)
        .then(data => {
          this.growler.growl(`The invoice has been saved, you'll see a new diary entry in a few minutes in your dashboard once the paperwork has been generated.`, "Save complete", "growl-info-header", "info");
          this.router.navigate(this.dataService.newLocation("home"));
        })
        .catch(err => this.handleError(err, "Failed to save invoice"))
    }

    onLoaded() {
      this.title = "Editing invoice";
    }

    customerChanged(customer: any) {
      this.record.customerInternalReference =customer.internalReference;
    }

    reloadRequest() {
      this.load();
    }

}
