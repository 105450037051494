import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'form-toolbar',
  template: `
    <section class="form-toolbar">
        <button mat-fab color="primary">Save</button>
        <button type="button" (click)="resetForm()" mat-fab color="accent">Cancel</button>
        <span class="pad-left">{{message}}</span>
    </section>
    `
})
export class FormToolbarComponent {

    @Input() message: string = "Edit";
    @Output() reset = new EventEmitter<void>();

    resetForm() {
        this.reset.emit();
    }

}
