import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { MovementService } from '../services/movement.service';
import { MappingService } from '../services/mapping.service';

declare var H: any;

@Component({
  selector: 'app-new-movement',
  templateUrl: './newmovement.component.html',
  styleUrls: ['./newmovement.component.scss']
})
export class NewMovementConponent extends BaseComponent implements OnInit, AfterViewInit {

  private service = new MovementService(this.dataService);
  private mapping: MappingService;

  drivers: any = [];
  orderId: any;
  vehicleId: any;
  journalId: any;
  sourceHint: string = 'Deliver from';
  destHint: string = 'Deliver to';

  @ViewChild('map')
  public mapElement: ElementRef;

  public title: string = "New vehicle movement";

    ngOnInit() {
      this.loadDriverList();
      this.route.params.subscribe((params: Params) => {
        this.orderId = +params['orderId'];
        this.vehicleId = +params['vehicleId'];
        this.journalId = +params['journalId'];
        if (this.orderId) {
          this.initialise(this.orderId, 'movement/fororder');
        }
        else if (this.vehicleId) {
          this.initialise(this.vehicleId, 'movement/forvehicle');
        }
        else {
          this.record = {
            contact: undefined,
            contactId: undefined,
            customerAccountId: undefined,
            vehicleId: undefined,
            orderReference: undefined,
            movementType: undefined,
            canSelectContact: true,
            canSelectCustomer: true,
            canSelectVehicle: true
          }
        }
      });
    }

    ngAfterViewInit() {
      this.mapping = new MappingService(H, this.mapElement, undefined, this.dataService);
    }
  
    loadDriverList() {
      this.dataService.loadall(`users/role/driver`)
        .subscribe(
          (response: any[]) => { this.drivers = response; },
          (error: any) => console.log("Failed to load list of drivers")
      );
    }

    driverChanged(driver: any) {
      this.record.contactId = driver.id;
    }

    vehicleChanged(vehicle: any) {
      this.record.vehicleId = vehicle.id;
    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");  
        return false;
      }
      this.service.save(this.record)
        .then(data => {
          this.growler.growl(`The movement has been saved.`, "Save complete", "growl-info-header", "info");  
          this.router.navigate(this.dataService.newLocation("movements"));
        })
        .catch(err => this.handleError(err, "Failed to save movement"))
    }
    onLoaded() {
      this.entity = 'movement';
      this.title = `New ${this.record.movementType||'Movement'} Job`;
      if (this.record.movementType == 'Collection') {
        this.sourceHint = 'Collect from';
        this.destHint = 'Return to';
      }
      console.log(this.record.movementDate);
      //this.title = `Create movement for order ${this.record.id}`;
    }

}
