export class Vehicle {
    id?: number;
    idsCode?: string;
    vrm?: string;
    chassisNumber?: string;
    externalAssetId?: string;
    colour?: string;
    availableDate?: Date;
    manufacturer?: string;
    model?: string;
    variant?: string;
    fuel?: string;
    bodyStyle?: string;
    transmission?: string;
    status?: string;
    trim?: string;
    category?: string;
    options?: string;
    notes?: string;
    imageFilename?: string;
    initialTerm?: number;
    termMonths?: number;
    contractMileage?: number;
    monthlyPayment?: number;

    hasvrm(): boolean {
        return (new VehicleHelper()).validvrm(this.vrm);
    }
}

export class VehicleHelper {
    private regex_ukvrm = /\b[a-z]{2}[0-9]{2}[a-z]{3}\b/i;

    validvrm(vrm: string): boolean {
        return vrm && vrm.match(this.regex_ukvrm) != null;
    }
}