import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { GridSelectComponent } from '../base/base.component';
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'contactselect',
  templateUrl: './contactselect.component.html',
  styleUrls: ['./contactselect.component.scss']
})
export class ContactSelectComponent extends GridSelectComponent implements AfterViewInit {

    @Input() customerId: number = undefined;
    @Input() title: string = "Contact";
    @Input() selectCaption: string = "(please select a contact...)";
    @ViewChild("contact") contact: ContactComponent;
    
    dataColumns = [ "icon", "firstName" ];
  
    ngAfterViewInit() {
      this.collectionName = (this.customerId) ? `contacts/customer/${this.customerId}` : undefined;
      this.updateCollection();
      this.initialise(this.id, "contact");
    }
  
    onLoaded() {
  
    }
    
    editContact() {
      this.contact.load(this.record.id);
    }

    contactChanged(contact) {
      this.forceRefresh();
    }
    
    select(row) {
      this.updateId(row.id);
    }
}
  