import { Component, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Params } from '@angular/router';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent extends BaseComponent implements OnInit {

  public title: string = "New report";

    ngOnInit() {
      this.route.params.subscribe((params: Params) => {
        this.initialise(+params['id'], 'report');
      });
    }

    onRecordChanged(quotation: any) {

    }

    onSubmit(form) {
      if (!form.valid) {
        this.growler.growl(`Please ensure you have completed all of the required fields.`, "Information missing", "growl-error-header", "info");
        return false;
      }
      this.dataService.save(this.record, "report")
        .subscribe(
          (response: any[]) => {
            this.growler.growl(`The report has been saved.`, "Save complete", "growl-info-header", "info");
            this.router.navigate(this.dataService.newLocation("reports"));
          },
          (error: any) => this.handleError(error, "Failed to save report")
        );
    }

    onLoaded() {
      this.entity = 'report';
      this.title = "Editing report";
    }

    reloadRequest() {
      this.load();
    }
}
