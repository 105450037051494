class Helper {

    ensureDate(date: any): Date {
        if (!date) {
            return undefined;
        }

        if (typeof date === "string") {
            return new Date(date);
        }
        return date;
    }

    dbDate(date: any): string {
        let d = this.ensureDate(date);
        if (!d) {
            return undefined;
        }

        return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
    }

    dbDatetime(date: any): string {
        let d = this.ensureDate(date);
        if (!d) {
            return undefined;
        }

        return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} ${d.toLocaleTimeString()}`;
    }

    displayDate(date: any): string {
        let d = this.ensureDate(date);
        if (!d) {
            return "na";
        }

        return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;
    }
}

export default new Helper();