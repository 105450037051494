export class AddressHelper {
    public formatAddress(record: any, invalidMsg: string): string {
        if (!record.houseNameNo && !record.street) {
            return invalidMsg;
        }

        let result = record.houseNameNo;
        if (record.street) {
            result = `${result ? result + ' ' : ''}${record.street}`;
        }
        if (record.town) {
            result = `${result ? result + ', ' : ''}${record.town}`;
        }
        return result
    }
}