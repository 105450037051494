import { Component, OnInit, ViewChild } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';
import { DialogJournalComponent } from '../shared/dialog/dialog.journal.component';
import { ItemJournalComponent } from '../itemjournal/itemjournal.component';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends GridSelectComponent implements OnInit {

  collectionName = 'journal';
  dataColumns = [ 'icon', 'text', 'customerName', 'action' ];
  tmr = undefined;

  @ViewChild('journal') journal: ItemJournalComponent;

  ngOnInit() {
    this.checkForUpdates();
  }

  private checkForUpdates() {
    window.setTimeout(() => {
      this.growler.growl('We\'re checking for updated diary events...', 'Refreshing', 'growl-info-header', 'info');
      window.setTimeout(() => {
        if (this.router.url === '/home') {
          this.updateCollection();
          this.checkForUpdates();
        }
      }, 1500);
    }, 300000);
  }

  onLoaded() {

  }

  public tabChange(event) {
    if (event.index == 1) {
      this.journal.updateCollection(`journal/team`);
    }
  }

  public selectCustomer(row) {
    this.dataService.returnUrl = 'home';
    this.router.navigate(['customer', row.customerId]);
  }

  public createDelivery(orderId, journalId) {
    // Create a new delivery movement for this rental
    this.dataService.returnUrl = 'home';
    this.router.navigate(['newmovement', orderId, journalId]);
  }

  public editJournal(row: any): any {
    if (!row.action) {
      // If there's no explicit action, just quick mark as dealt with
      this.dataService.save(row, 'journal/action').subscribe(
        data => {
          row.status = 'journal-actioned';
          row.iconClass = 'checked-ok';
          row.icon = 'done';
          row.canAction = false;
        },
        error => this.handleApiError(error, 'Failed to update the journal entry')
      )
    } else {
      const dialog = this.dialog.open(DialogJournalComponent, {
        data: row
      });
      dialog.afterClosed().subscribe(result => {
        // result will be the amended row
      });
    }
  }

  acknowledge2(row) {
    this.dataService.save(row, 'journal/action').subscribe(
      data => {
        row.status = 'journal-actioned';
        row.iconClass = 'checked-ok';
        row.icon = 'done';
        row.noack = true;
      },
      error => this.handleApiError(error, 'Failed to update the journal entry')
    )
  }

}

