import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GrowlerService } from '../core/growler/growler.service';
import { DataService } from '../services/data.service';
import { FormControl } from '@angular/forms';
import { Vehicle } from '../model/vehicle';
import { VehicleService } from '../services/vehicle.service';
import { UserService } from '../services/user.service';
import { DocumentSelectComponent } from '../documentselect/documentselect.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {

  private service: VehicleService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private growler: GrowlerService,
    private userService: UserService,
    private dataService: DataService) {

    this.service = new VehicleService(this.dataService);
  }

    /*
    Workaround for duff expansion panel rendering behaviour in non-active tab
    */
    selectedTabIndex = 0;
    tabChange(event) {
      Promise.resolve().then(() => this.selectedTabIndex = event.index);
    }

    statusMapping: any = [];
    financeCompanies: any = [
      "",
      "Aldermore",
      "Amicus Asset Finance",
      "Bentley Financial Services",
      "Close Brothers",
      "Clydesdale Yorkshire Bank",
      "Hampshire Trust Bank",
      "Haydock Finance",
      "Henry Howard Finance",
      "Ignition",
      "Investec",
      "JBR Capital",
      "Liberty Leasing",
      "Lombard",
      "Merc Benz Contract Hire",
      "Mercedes Benz Finance",
      "Metro Bank SME",
      "Norton Folgate",
      "Paragon Bank / Car Finance",
      "Praetura Asset Finance",
      "Private & Commercial Finance",
      "Quantum Funding Ltd",
      "Renaissance Asset Finance",
      "Shawbrook Bank Ltd",
      "Simply Asset Finance",
      "Ultimate Asset Finance",
      "United Trust Bank",
      "VRS Finance",
      "XL",
      "XLMAC",
      "XLHW",
      "XLCSF",
      "XLCST",
      "XLGH"
    ];
    leaseCompanies = [
      "",
      "Alliance",
      "Easylease",
      "LCVR",
      "Liquid Fleet",
      "United Rental Group",
      "VRS",
      "XLMAC"
    ];
    loadmessage: string  = '';
    loadfailed = false;
    id: any;

    vehicleImages: Array<any> = [];
    vehicle: any = {
      vatQ: true,
      net: undefined,
      vat: undefined
    };
    vehicleSelect: any = {};

    private invoicePrice = new FormControl();
    private netCost = new FormControl();
    private deliveryFee = new FormControl();
    private priceOptions = new FormControl();
    private roadFundLicence = new FormControl();
    private firstRegFee = new FormControl();
    private grossCost = new FormControl();
    private discount = new FormControl();

    @ViewChild("documents") documents: DocumentSelectComponent;

    ngOnInit() {
      this.statusMapping = this.dataService.vehicleStatusList();
      let lookup = this.dataService.getFromLocalStorage("staticdata");
      if (lookup) {
        this.vehicleSelect.bodyStyles = lookup.filter(function(i) { return i.type == "bodyStyle"; });
        this.vehicleSelect.fuel = lookup.filter(function(i) { return i.type == "fuel"; });
        this.vehicleSelect.transmission = lookup.filter(function(i) { return i.type == "transmission"; });
      }

      this.route.params.subscribe((params: Params) => {
        this.id = +params['id'];
        this.load();
      });

      // Hooks for vehicle field changes to power calculated fields
      // This whole mechanism is not really the right way to do things and needs re-factoring

      let that = this;
      let trigger = {
        _active: false,
        _instance: undefined,
        _number: function(prop:any) {
          let value = trigger._instance.vehicle[prop];
          if (value!=null && value!= undefined) {
            return parseFloat(value)
          }
          return 0;
        },
        _numberValue: function(prop:any) {
          let value = trigger._instance[prop];
          if (value && value.value!=null && value != undefined) {
            return parseFloat(value.value)
          }
          return 0;
        },
        priceChanges: function(v) {
          let net = trigger._updateNet();
          //let vat = trigger._updateVat(net);

          trigger.netChanges(net);
          trigger._active = false;
        },
        netChanges: function(v) {
          trigger._updateVat(v);
          let vatToAdd = (trigger._instance.vehicle.vatQ) ? trigger._number('vat') : 0;
          let vs = (typeof v == "string") ? parseFloat(v) : v;

          //let gross = (v+(trigger._instance.vehicle.vat||0) +(trigger._instance.vehicle.firstRegFee||0)+(trigger._instance.vehicle.roadFundLicence||0));
          let gross = (vs+vatToAdd+trigger._number('firstRegFee')+trigger._number('roadFundLicence'));
          if (typeof gross == "string") {
            gross = parseFloat(gross);
          }
          trigger._instance.vehicle.grossCost = gross.toFixed(2);
          //(v+(trigger._instance.vehicle.vat||0) +(trigger._instance.vehicle.firstRegFee||0)+(trigger._instance.vehicle.roadFundLicence||0)).toFixed(2);
          trigger.grossChanges(trigger._number('grossCost'));
          //trigger.grossChanges(v+(trigger._instance.vehicle.vat||0) +(trigger._instance.vehicle.firstRegFee||0)+(trigger._instance.vehicle.roadFundLicence||0));
          trigger._active = false;
        },
        rflChanges: function(v) {
          //   NET, VAT, RFL, FRF >> GROSS COST
          trigger._instance.vehicle.grossCost = (
            (trigger._number('netCost')) +
            (trigger._number('vat')) +
            (trigger._number('firstRegFee')) +
            (v||0)
          ).toFixed(2);
          trigger.grossChanges(trigger._number('grossCost'));
          trigger._active = false;
        },
        frfChanges: function(v) {
          //   NET, VAT, RFL, FRF >> GROSS COST
          trigger._instance.vehicle.grossCost = (
            (trigger._number('netCost')) +
            (trigger._number('vat')) +
            (trigger._number('roadFundLicence')) +
            (v||0)
          ).toFixed(2);
          trigger.grossChanges(trigger._number('grossCost'));
          trigger._active = false;
        },
        grossChanges: function(v) {
          // Direct change to gross price field, need to calc discount
          trigger._updateDiscount(v);
          trigger._active = false;
        },
        _updateNet: function() {
          // "Net value" = "Vehicle Price-Discount+delivery+Options"
          trigger._instance.vehicle.netCost = (
            ((trigger._numberValue("invoicePrice")) +
             (trigger._numberValue("deliveryFee")) +
             (trigger._numberValue("priceOptions"))) -
            ((trigger._numberValue("discount")))
          ).toFixed(2);
          return trigger._number('netCost');
        },
        _updateVat: function(net: any) {
          let vat = ((net>0) ? net*0.2 : 0).toFixed(2);
          trigger._instance.vehicle.vat = vat;
          return vat;
        },
        _updateDiscount: function(gross: any) {
          if (!trigger._instance.vehicle.discount) {
            trigger._instance.vehicle.discountPercent = 0;
            return 0;
          }
          // Discount % = 1-("gross cost/(((Vehicle price+options)x1.2)+RFL+FRF)) (edited)
          let price = (trigger._number('invoicePrice'));
         let options = (trigger._number('priceOptions'));
         let rfl = (trigger._number('roadFundLicence'));
         let frf = (trigger._number('firstRegFee'));

          if ((price==0 && options==0) || gross==0) {
            return false;
          }

          trigger._instance.vehicle.priceP11D = (((price+options+(trigger._number('deliveryFee')))*1.2)+rfl+frf);

          let dc = ((1-(gross/(((price+options)*1.2)+rfl+frf)))*100).toFixed(2);
          // TODO Formula is incorrect...
          trigger._instance.vehicle.discountPercent = dc;
          return dc;
        },
        ready: function(page: any, value: any, force: boolean = false) {
          if (trigger._active && !force) {
            return false;
          }
          trigger._instance = page;
          trigger._active = true;
          return true;
        }

      }

      this.invoicePrice.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.priceChanges(v) }});

      this.deliveryFee.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.priceChanges(v) }});
      this.priceOptions.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.priceChanges(v) }});
      this.discount.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.priceChanges(v) }});

      this.netCost.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.netChanges(v) }});

      this.roadFundLicence.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.rflChanges(v) }});
      this.firstRegFee.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.frfChanges(v) }});

      this.grossCost.valueChanges.subscribe(v => { if (trigger.ready(that, v, false)) { trigger.grossChanges(v) }});

      // Change triggers
      //  / VEHICLE PRICE, DISCOUNT, DELIVERY, OPTIONS >> NET
      //  / NET >> VAT
      //   NET, VAT, RFL, FRF >> GROSS COST
      //   GROSS, VEHICLE PRICE, OPTIONS, RFL, FRF >> DISCOUNT
      /*
"Net value" = "Vehicle Price-Discount+delivery+Options"
"VAT" = "Net" x 0.2
Discount % = "gross cost/(((Vehicle price+options)x1.2)+RFL+FRF)
"Gross Cost" = "Net+VAT+RFL+FRF"
Discount % = 1-("gross cost/(((Vehicle price+options)x1.2)+RFL+FRF)) (edited)
Can P11d value become (((Vehicle price+Options+Delivery)/1.2)+RFL+FRF)
      */
    }
    load() {
      this.loadfailed = false;
      this.loadmessage = 'Loading vehicle details...';
      if (!this.id || this.id === 0) {
        this.loadmessage = '';
        this.vehicle = { vatQ: true, net: undefined, vat: undefined, firstRegFee: 55 };
        this.vehicle.internalReference = this.dataService.newGuid();
        this.growler.growl('Please enter details for the new vehicle designation.', "Information", "growl-info-header", "info");
        if (!this.vehicleSelect.manufacturers) {
          this.fetchManufacturers();
        }
        return false;
      }
      this.dataService.vehicle(this.id).subscribe(
        data => {
          this.loadmessage = '';
          this.vehicle = data;
          if (this.vehicle.vatQ === undefined) {
            this.vehicle.vatQ = true;
          }
          if (this.vehicle.firstRegFee === undefined) {
            this.vehicle.firstRegFee = 55;
          }
          if (!this.vehicle.images) {
            this.vehicle.images = [];
          }
          this.vehicleImages = (this.vehicle.images).map((e: any) => {
            return {
              path: e.path ? `https://legacyapi.seabeckconsulting.co.uk${e.path}` : undefined,
              title: e.title
            }
          })
          //console.log(this.vehicleImages);
          this.clearArray(this.vehicle.images);
          delete this.vehicle.images;
          if (!this.vehicleSelect.manufacturers) {
            this.fetchManufacturers();
          }
        },
        error => {
          this.loadfailed = true;
          this.loadmessage = 'Details for the vehicle could not be retrieved';
          this.handleApiError(error, "Failed to save vehicle");
        }
      )
    }

    showDocuments() {
      this.documents.show("Vehicle related documents", "vehicle", this.vehicle.internalReference, "v5-image");
    }
    onSubmit(form) {
      // Take a copy of the active vehicle record and dump the lookup stuff that we shouldn't be trying to submit
      /*
      let vehicle = JSON.parse(JSON.stringify(this.vehicle));
      this.clearArray(vehicle.colours);
      this.clearArray(vehicle.trims);
      this.clearArray(vehicle.availableOptions);
      delete vehicle.colours;
      delete vehicle.trims;
      delete vehicle.availableOptions;
      */
      let vehicle = this.service.submittableRecord(this.vehicle);
      this.dataService.saveVehicle(vehicle).subscribe(
        data => {
          this.growler.growl('The vehicle has been saved.', "Save complete", "growl-info-header", "info");
          this.router.navigate(this.dataService.newLocation('vehicles'));
        },
        error => this.handleApiError(error, "Failed to save vehicle")
      )
    }
    handleApiError(err, title) {
      if (err.loginRedirectRequired && err.loginRedirectRequired === true) {
        this.userService.logout();
        this.growler.growl("Your login session has expired, you will need to log again in to continue.", "Warning", "growl-info-header", "info");
        this.router.navigate(["login"]);
      }
      else {
        this.growler.growl(err.detail || "An unknown error occured", title, "growl-error-header", "error");
      }
    }
    resetForm() {
      this.router.navigate(this.dataService.newLocation('vehicles'));
    }
    onLookupVrm() {
      debugger;
      const instance = this;
      this.vehicleSelect.lookingUpVrm = true;
      this.dataService.findByVrm(this.vehicle.vrm).subscribe(
        vehicle => {
          console.log(`DEBUG:: Vehicle lookup result ${JSON.stringify(vehicle, null, 4)}`);
          instance.vehicleSelect.lookingUpVrm = false;
          instance.vehicle = instance.resetVehicle();

          console.log(`DEBUG:: Manufacturers ${JSON.stringify(instance.vehicleSelect.manufacturers, null, 4)}`);
          // Try and find the vehicle stuff from the list providers so we get properly matched up
          let lk1 = instance.vehicleSelect.manufacturers.find(i =>
            i.value && vehicle.data.manufacturerName && vehicle.data.manufacturerName.toLowerCase() == i.value.toLowerCase()
          );
          if (lk1) { vehicle.data.manufacturerName = lk1.value; }
          else {
            if (!instance.vehicleSelect.manufacturers) { instance.vehicleSelect.manufacturers = []; }
            instance.vehicleSelect.manufacturers.push({ value: vehicle.data.manufacturerName });
          }

          /* Assign the vehicle properties from the found vehicle */

          instance.vehicle.chassisNumber = (vehicle.data.dvla && vehicle.data.dvla.vehicleIdentificationNumber) ? vehicle.data.dvla.vehicleIdentificationNumber : this.vehicle.chassisNumber;
          instance.vehicle.dateRegistered = vehicle.data.dateOfVehicleRegistration || instance.vehicle.dateRegistered;

          instance.vehicle.manufacturer = vehicle.data.manufacturerName;
          instance.vehicle.idsCode = vehicle.data.idscode;
          instance.vehicle.model = vehicle.data.modelGroup;
          instance.vehicle.variant = vehicle.data.vehicleTreeDescription;
          instance.vehicle.transmission = vehicle.data.transmissionType;
          instance.vehicle.colour = (vehicle.data.dvla && vehicle.data.dvla.vehicleColour) ? vehicle.data.dvla.vehicleColour : instance.vehicle.colour;
          instance.vehicle.fuel = vehicle.data.fuelType;
          instance.vehicle.bodyStyle = vehicle.data.bodyStyle;
          instance.vehicle.numberOfDoors = vehicle.data.doors;
          instance.vehicle.engineCC = vehicle.data.cc;
          instance.vehicle.gears = vehicle.data.gears; // Not seen in sample data so far
          instance.vehicle.co2 = vehicle.data.co2emissions;
          instance.vehicle.powerBHP = vehicle.data.bhp;
          // TODO Augment this with a lookup in the IDS database for the IDS code, in case we can add additional technical data

          instance.fetchModels({ "value": instance.vehicle.manufacturer });
          this.vehicle.colours = vehicle.colours;
          this.vehicle.trims = vehicle.trims;
          this.vehicle.availableOptions = vehicle.availableOptions;
          if (vehicle.technical) {
            Object.assign(this.vehicle, vehicle.technical);
          }
      },
        error => {
          instance.vehicleSelect.lookingUpVrm = false;
          instance.handleApiError(error, "Failed to lookup vehicle");
        }
      );
    }

    resetVehicle() {
      let result = {
        "id": this.vehicle.id,
        "internalReference": this.vehicle.internalReference,
        "manufacturer": this.vehicle.manufacturer,
        "model": this.vehicle.model,
        "vrm": this.vehicle.vrm,
        "status": this.vehicle.status,
        "availableDate": this.vehicle.availableDate,
        "chassisNumber": this.vehicle.chassisNumber,
        "externalAssetId": this.vehicle.externalAssetId,
        "fleetId": this.vehicle.fleetId,
        "notes": this.vehicle.notes,
        "initialTerm": this.vehicle.initialTerm,
        "termMonths": this.vehicle.termMonths,
        "contractMileage": this.vehicle.contractMileage,
        "monthlyPayment": this.vehicle.monthlyPayment
      }
      return result;
    }

    onChangeManufacturer(e) {
      // Reset variant, ids code, model, load model list request
      if (this.vehicle.model || this.vehicle.variant) {
        this.growler.growl('You have changed the manufacturer, we have had to reset most of the vehicle details accordingly.', "Please note", "growl-info-header", "info");
      }
      this.vehicle = this.resetVehicle();
      delete this.vehicle.model;
      this.fetchModels(e);
    }
    onChangeModel(e) {
      // Reset variant, ids code, load variant list request
      if (this.vehicle.variant) {
        this.growler.growl('You have changed the model type, we have had to reset most of the vehicle details accordingly.', "Please note", "growl-info-header", "info");
      }
      this.vehicle = this.resetVehicle();
      this.fetchVariants(e);
    }
    onChangeVariant(e) {
      // If we select a variant with an IDS code, load up the details from that IDS item
      // colours, trims, availableOptions
      // Clear existing stuff
      this.clearArray(this.vehicle.colours);
      this.clearArray(this.vehicle.trims);
      this.clearArray(this.vehicle.availableOptions);

      if (!e || !e.value || !this.vehicleSelect.variants) {
        return false;
      }

      let v = this.vehicleSelect.variants.find(function(item) { return item.value == e.value && item.idsCode; });
      if (v && v.idsCode) {
        this.vehicleSelect.loadingOptions = true;
        // TODO Port in the details using this IDS code
        this.vehicle.idsCode = v.idsCode;
        this.vehicle.bodyStyle = v.bodyStyle;
        this.vehicle.transmission = v.transmission;
        this.vehicle.fuel = v.fuel;
        this.dataService.getVehicleOptions(v.idsCode).subscribe(
          data => {
            delete this.vehicleSelect.loadingOptions;
            this.vehicle.colours = data.colours;
            this.vehicle.trims = data.trims;
            this.vehicle.availableOptions = data.availableOptions;
            if (data.technical) {
              Object.assign(this.vehicle, data.technical);
            }
          },
          err => { delete this.vehicleSelect.loadingOptions; }
        );
      }
    }
    onChangeVariantBodyStyle(e) {
      // Change filteredVariants based on those with the correct body style
      if (this.vehicleSelect.variants && e && e.value) {
        this.vehicleSelect.filteredVariants = this.vehicleSelect.variants.filter(v =>
          v.bodyStyle == e.value && (!this.vehicle.fuel || (this.vehicle.fuel == v.fuel))
        );
      }
    }
    onChangeVariantFuel(e) {
      // Change filteredVariants based on those with the correct fuel type
      if (this.vehicleSelect.variants && e && e.value) {
        this.vehicleSelect.filteredVariants = this.vehicleSelect.variants.filter(v =>
          v.fuel == e.value && (!this.vehicle.bodyStyle || (this.vehicle.bodyStyle == v.bodyStyle))
        );
      }
    }

    fetchManufacturers() {
      function done(instance, data) {
        // l = l.map(e => e = { id: e.id, value: e.value || e.name, name: e.value || e.name });
        instance.vehicleSelect.manufacturers = data.map(e => e = { id: e.id, value: e.value || e.name, name: e.value || e.name });
        let lk = (instance.vehicleSelect.manufacturers.find(m => {
          return m.value && instance.vehicle.manufacturer && (m.value.toLowerCase() == instance.vehicle.manufacturer.toLowerCase())
        }))
        if (lk) {
          if (instance.vehicle.manufacturer != lk.value) { instance.vehicle.manufacturer = lk.value; }
          instance.fetchModels({ value: instance.vehicle.manufacturer });
        }
        else {
          delete instance.vehicleSelect.models;
        }
        delete instance.vehicleSelect.loadingManufacturers;
        return true;
      }

      this.vehicleSelect.loadingManufacturers = true;
      const data = this.dataService.getFromLocalStorage("manufacturers");
      if (data) { return done(this, data); }

      this.dataService.getManufacturers().subscribe(
        data => { return done(this, data); },
        err => { delete this.vehicleSelect.loadingManufacturers; }
      );
    }
    fetchModels(e) {
      const instance = this;
      instance.vehicleSelect.loadingModels = true;
      instance.dataService.getModels(e.value).subscribe(
        data => {
          instance.vehicleSelect.models = data;
          let lk = (instance.vehicleSelect.models.find(m => {
            //console.log(`check:: ${m.value.toLowerCase()} <> ${}`);
            return m.value && instance.vehicle.model && (m.value.toLowerCase() == instance.vehicle.model.toLowerCase());
          }))
          if (lk) {
            if (instance.vehicle.model != lk.value) {
              instance.vehicle.model = lk.value; // Make sure it's case-matched
            }
            instance.fetchVariants({ value: instance.vehicle.model });
          }
          else {
            instance.clearArray(instance.vehicleSelect.variants);
            instance.clearArray(instance.vehicleSelect.filteredVariants);
            instance.clearArray(instance.vehicleSelect.variantBodyStyle);
            instance.clearArray(instance.vehicleSelect.variantFuel);
          }
          delete instance.vehicleSelect.loadingModels;
        },
        err => { delete instance.vehicleSelect.loadingModels; }
      );
    }
    fetchVariants(e) {
      const instance = this;
      if (!instance.vehicle.manufacturer) { return false; }
      instance.vehicleSelect.loadingVariants = true;
      instance.dataService.getVariants(instance.vehicle.manufacturer, e.value).subscribe(
        data => {
          instance.vehicleSelect.variants = data;
          // Distinct copy for the filtered list
          instance.vehicleSelect.filteredVariants = JSON.parse(JSON.stringify(data));
          instance.clearArray(instance.vehicleSelect.variantBodyStyle);
          instance.clearArray(instance.vehicleSelect.variantFuel);
          instance.vehicleSelect.variantBodyStyle = [];
          instance.vehicleSelect.variantFuel = [];
          for (let v of instance.vehicleSelect.variants) {
            if (instance.vehicleSelect.variantBodyStyle.indexOf(v.bodyStyle) == -1) {
              instance.vehicleSelect.variantBodyStyle.push(v.bodyStyle);
            }
            if (instance.vehicleSelect.variantFuel.indexOf(v.fuel) == -1) {
              instance.vehicleSelect.variantFuel.push(v.fuel);
            }
          }

          if (instance.vehicle.variant && instance.vehicleSelect.variants) {
            let lk = (instance.vehicleSelect.variants.find(m => {
              return m.value && instance.vehicle.variant && (m.value.toLowerCase() == instance.vehicle.variant.toLowerCase());
            }))
            if (lk && instance.vehicle.variant != lk.value) {
              instance.vehicle.variant = lk.value;
            }
          }

          delete instance.vehicleSelect.loadingVariants;
        },
        err => { delete instance.vehicleSelect.loadingVariants; }
      );
    }

    optionChosen(optionText: string) {
      return (this.vehicle.options && this.vehicle.options.includes(optionText));
    }
    addOption(e: any, optionText: string) {
      if (this.vehicle.options && this.vehicle.options.includes(optionText)) {
        return false;
      }
      this.vehicle.options = (this.vehicle.options === undefined || this.vehicle.options == null || this.vehicle.options == '') ? optionText : (this.vehicle.options + '\n' + optionText);
    }

    statusInformation() {
      let status = (`${this.vehicle.status || '(status not specified)'}`).toUpperCase();
      if (status == 'ONHIRE') status = 'ON HIRE';
      return `${status}${this.vehicle.availableDate ? ' : ': ''}`;
    }
    designationDescription() {
      if (!this.vehicle.manufacturer && !this.vehicle.model) {
        return "(please select vehicle type...)";
      }
      else if (!this.vehicle.model) {
        return `${this.vehicle.manufacturer}...`;
      }
      else if (!this.vehicle.manufacturer) {
        return `(manufacturer??) ${this.vehicle.model}`;
      }
      else if (!this.vehicle.variant) {
        return `${this.vehicle.manufacturer} ${this.vehicle.model}`;
      }
      return `${this.vehicle.manufacturer} ${this.vehicle.model} ${this.vehicle.variant}`;
    }
    configurationDescription() {
      if (!this.vehicle.engineCC && !this.vehicle.gears && !this.vehicle.numberOfDoors && !this.vehicle.bodyStyle) {
        return "(vehicle configuration not set...)";
      }
      let result = this.vehicle.bodyStyle ? this.vehicle.bodyStyle : undefined;
      if (this.vehicle.engineCC) {
        result = result ? `${result}, ${this.vehicle.engineCC}cc` : `${this.vehicle.engineCC}cc`;
      }
      if (this.vehicle.gears) {
        const suffix = this.vehicle.transmission ? `spd ${this.vehicle.transmission}` : ' gears';
        result = result ? `${result}, ${this.vehicle.gears}${suffix}` : `${this.vehicle.gears}${suffix}`;
      }
      if (this.vehicle.numberOfDoors) {
        result = result ? `${result}, ${this.vehicle.numberOfDoors}dr` : `${this.vehicle.numberOfDoors}dr`;
      }
      return result;
    }
    colourAndTrim() {
      if (!this.vehicle.colour && !this.vehicle.trim) {
        return "(please select colour and trim...)";
      }
      else if (!this.vehicle.trim) {
        return this.vehicle.colour;
      }
      else if (!this.vehicle.colour) {
        return `(colour?) ${this.vehicle.trim}`;
      }
      return `${this.vehicle.colour} / ${this.vehicle.trim}`;
    }

    clearArray(e) {
      if (!e || e.length < 1) { e = []; return false; }
      e.splice(0, e.length);
    }

}
