import { Component, OnInit } from '@angular/core';
import { GridSelectComponent } from '../base/base.component';

@Component({
  selector: 'app-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss']
})
export class MovementsConponent extends GridSelectComponent implements OnInit {

  collectionName = "movements";
  dataColumns = [ "registration", "contact", "source", "status" ];
  userRole: string;

  ngOnInit() {
    //this.userRole = this.userService.claims.role;
  }

  onLoaded() {

  }

  selectRow(row) {
    this.dataService.returnUrl = "movements";
    this.router.navigate(["movement", row.id]);
  }

}
