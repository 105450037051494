import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'dialog-yesno',
  template: `
  <h1 mat-dialog-title>{{ data.title || "Question" }}</h1>

  {{ data.question }}
  
  <div mat-dialog-actions>
    <button mat-button (click)="confirmSelection()" color="primary">
      Yes
    </button>
    <button mat-button (click)="dialogRef.close(false)">
      No
    </button>
  </div>  `
})
export class DialogYesNoComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogYesNoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  confirmSelection() {
    this.dialogRef.close(true);
  }

}